// Const
import * as commonConst from '../actions/const/common.const';
import * as companyConst from '../actions/const/company.const';
import * as investDetailsConst from '../../pages/invest-details/actions/invest-details.const';
import * as dashboardConst from '../../pages/dashboard/common/const/dashboard.const';
import * as landingConst from '../../pages/landing/const/landing.const';

export const initState = {
  id: '',
  siren: '',
  legal_form: [],
  name: '',
  address: '',
  city: '',
  cfi_code: '',
  zip: '',
  // TODO wait to use iso code 2
  country: 'France',
  bank_name: '',
  iban: '',
  fundraising_default: '',
  legalForms: [],
  bossStatuses: [],
  potentialCompanies: [],
  selectedCompanyInForm: {},
  in_progress: true,
  sirenNotUsed: true,
  user: {
    id: '',
    email: '',
    phone: '',
  },
  boss: {
    title: '',
    name: '',
    surname: '',
    status: '',
    email: '',
    phone: '',
  },
  data_from_insee: false,
  is_ceased: false,
};

const removeUselessDataRetrievedFromApi = company => {
  const fieldsToDelete = [
    'financial_intermediary',
    'accountId',
    'bank_address',
    'updatedAtDisplay',
    'entity',
    'createdAt',
    'updatedAt',
  ];
  let cleanCompanyData = { ...company };
  fieldsToDelete.forEach(currentField => {
    if (cleanCompanyData[currentField]) {
      delete cleanCompanyData[currentField];
    }
  });
  return cleanCompanyData;
};

const company = (state = initState, action) => {
  switch (action.type) {
    case commonConst.CLEAR_LOGOUT:
    case commonConst.CLEAR_REDUX:
    case commonConst.CLEAR_LOGOUT_PENDING:
    case landingConst.INIT_VIEW_PENDING:
    case landingConst.START_NEW_TEMPLATE_PENDING:
    case commonConst.INIT_STATE:
      return initState;

    case companyConst.SET_COMPANY_POTENTIAL_PLACES:
      return { ...state, potentialCompanies: action.potentialCompanies };

    case companyConst.GET_COMPANY_POTENTIAL_PLACES_FULFILLED:
      return { ...state, potentialCompanies: action.payload.data };

    case investDetailsConst.SET_COMPANY_DATA:
    case companyConst.SET_COMPANY_DATA: {
      let companyNewState = removeUselessDataRetrievedFromApi(action.company);
      if (!companyNewState.user) {
        // If company (id) change in form, we keep email field empty and iban
        companyNewState.user = {
          id: state.user.id,
          email: '',
          phone: state.user.phone,
        };
        companyNewState.iban = '';
      }
      return { ...state, ...companyNewState };
    }

    case companyConst.SET_COMPANY_KIND_PROVIDER_DATA:
      return { ...state, companyKindProvider: action.companyKindProvider };

    case companyConst.SET_COMPANY_SIREN:
      return { ...state, siren: action.siren };

    case investDetailsConst.SET_COMPANY_SIREN_NOT_USED:
      return { ...state, sirenNotUsed: action.sirenNotUsed };

    case companyConst.SET_COMPANY_BOSS_PHONE:
      return { ...state, boss: { ...state.boss, phone: action.phone } };

    case companyConst.SET_COMPANY_USER_PHONE:
      return { ...state, user: { ...state.user, phone: action.phone } };

    //TODO: useless ?
    case dashboardConst.INIT_COMPANY: {
      return {
        ...state,
        id: action.data.id || null,
        email: action.data.email || '',
        name: action.data.name || '',
      };
    }

    case companyConst.RESET_ID_COMPANY:
      return { ...state, id: '' };

    case companyConst.SET_COMPANIES_LEGAL_FORMS: {
      return {
        ...state,
        legalForms: action.legalForms,
      };
    }

    case companyConst.SET_COMPANIES_BOSS_STATUS: {
      return {
        ...state,
        bossStatuses: action.bossStatus,
      };
    }

    case companyConst.SET_SELECTED_COMPANY: {
      return {
        ...state,
        selectedCompanyInForm: action.suggestion,
      };
    }

    case companyConst.RESET_COMPANY_DATA:
      return initState;

    case companyConst.RESET_SELECTED_COMPANY: {
      return {
        ...initState,
      };
    }
    case companyConst.SET_COMPANY_AUTOCOMPLETE_INSEE_SIREN: {
      return {
        ...state,
        data_from_insee: true,
      };
    }
    case companyConst.SET_COMPANY_CEASED_INSEE_SIREN: {
      return {
        ...state,
        is_ceased: action.isCeasedCompany,
      };
    }
    default:
      return state;
  }
};

export default company;
