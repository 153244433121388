import { getCountryDataList } from 'countries-list';
import * as countryI18N from 'i18n-iso-countries';
import { eeaMember } from 'is-european';
import { getAppLocale } from './CatalizrUtils';
import { Enum } from '@utocat/catalizr-const-sharing-lib';

const allCountries = getCountryDataList();
countryI18N.registerLocale(require('i18n-iso-countries/langs/fr.json'));
countryI18N.registerLocale(require('i18n-iso-countries/langs/en.json'));

export const getAllCountries = () => {
  const locale = getAppLocale();
  const list = allCountries.map(country => ({
    ...country,
    label_translated: countryI18N.getName(country.iso2, locale) || country.name,
    label: country.name,
    iso_code: country.iso2,
  }));
  return list;
};

/* European Economic Area Members */
const createFilterCountryForEEA = (additionalCountryList = []) => {
  return country => {
    if (eeaMember(country.iso2)) {
      return true;
    }
    return Array.isArray(additionalCountryList) && additionalCountryList?.includes(country.iso2);
  };
};

const getCountryListForEEE = (additionalCountryList = []) => {
  const locale = getAppLocale();
  const filterEEE = createFilterCountryForEEA(additionalCountryList);
  const list = allCountries
    .filter(country => filterEEE(country))
    .map(country => ({
      ...country,
      label_translated: countryI18N.getName(country.iso2, locale) || country.name,
      label: country.name,
      iso_code: country.iso2,
    }));
  return list;
};

export const getCountriesForPortfolio = portfolioType => {
  switch (portfolioType) {
    case Enum.Accounts.PortfolioType.PEA:
    case Enum.Accounts.PortfolioType.PEA_PME:
      return getCountryListForEEE();
    case Enum.Accounts.PortfolioType.PERI:
    case Enum.Accounts.PortfolioType.PEE:
      // Suisse (CH) eligible
      return getCountryListForEEE(['CH']);
    case Enum.Accounts.PortfolioType.CTO:
    case Enum.Accounts.PortfolioType.CTJ:
    case Enum.Accounts.PortfolioType.CPE:
    default:
      return getAllCountries();
  }
};

export const getTranslatedFranceCountry = () => {
  // const locale = getAppLocale();
  // return countryI18N.getName('FR', locale);
  return 'France';
};
