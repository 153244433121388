import * as financialBrokerConst from '../const/financial-broker.const';

export const setFinancialBrokerData = companyDataFromApi => {
  // financial broker is attached to the company and therefore related data are stored into the company
  // main data => company.financial_intermediary // user => company.user
  let financialBrokerData = {
    ...companyDataFromApi.financial_intermediary,
  };
  financialBrokerData.user = companyDataFromApi.user;
  return {
    type: financialBrokerConst.SET_FINANCIAL_BROKER_DATA,
    data: financialBrokerData,
  };
};

export const resetFinancialBrokerData = () => ({
  type: financialBrokerConst.RESET_FINANCIAL_BROKER_DATA,
});
