import { Enum } from '@utocat/catalizr-const-sharing-lib';
import { getStore } from '../../../store';

// Actions
import * as CommonAction from './invest-details-common.actions';
import * as financialBrokerConst from '../../../shared/actions/const/financial-broker.const';
import {
  dispatchChangeToInvestDetailsForm,
  formatFloatValue,
} from '../../../shared/utils/CatalizrFormUtils';

// Constants
import { INVEST_DETAILS_REDUX_OBJECTS_NAMING } from '../../../shared/utils/CatalizrFormUtils';
// Api
import { getCompanies } from '../../../shared/api/company.api';

// --------------------------------------------------------------------------------------------------------
// DATA INITIALIZATION
// --------------------------------------------------------------------------------------------------------

/**
 * Initializes the financial broker data into redux and the 'invest-details' redux form.
 * @param {object} investment the investment data containing the financial broker to initialize
 * @param {function} dispatch the redux dispatch function
 * @void (dispatch the result data to redux & redux-form)
 */
export const initInvestDetailsWithFinancialBrokerData = (investment, dispatch) => {
  let financialBrokerData = {};
  let companyUser = null;

  if (investment.is_crowdfunding) {
    // delete some properties
    const intermediary = { ...investment.company?.financial_intermediary };
    delete intermediary.address;
    delete intermediary.zip;
    delete intermediary.city;
    delete intermediary.country;
    delete intermediary.data_from_insee;
    delete intermediary.legal_form;
    delete intermediary.siren;
    financialBrokerData = intermediary;
    // add new properties
    financialBrokerData.crowdfunding_fees = investment.crowdfunding_fees;
    financialBrokerData.isFromCrowdFunding = Enum.PDF.FieldOptionValue.TRUE;
    financialBrokerData.transfer_reference = investment.transfer_reference;
  } else {
    financialBrokerData.isFromCrowdFunding = Enum.PDF.FieldOptionValue.FALSE;
  }

  dispatchFinancialBrokerData(dispatch, financialBrokerData, companyUser);
};

// --------------------------------------------------------------------------------------------------------
// DATA CREATION & UPDATE
// --------------------------------------------------------------------------------------------------------

/**
 * Creates the object containing the financial broker data to send to the validate function.
 * @param {*} financialBrokerDataFromForm the financial broker data contained in the form
 */
export const buildFinancialBrokerData = financialBrokerDataFromForm => {
  const financialBrokerState = getStore().getState().financialBroker;
  const intermediariesList = financialBrokerState?.financialIntermediariesList;
  const [financialIntermediary] = intermediariesList.filter(
    item => item.label === financialBrokerDataFromForm.name,
  );
  const financialBrokerData = {
    crowdfunding_fees: financialBrokerDataFromForm?.crowdfunding_fees
      ? formatFloatValue(financialBrokerDataFromForm?.crowdfunding_fees)
      : 0,
    crowdfunding_transfer_ref: financialBrokerDataFromForm?.crowdfunding_transfer_ref,
    id: financialIntermediary?.id,
    registered_country: financialBrokerDataFromForm?.registered_country,
  };

  return financialBrokerData;
};

export const fetchFinancialIntermediaries = params => {
  return async dispatch => {
    dispatch({ type: financialBrokerConst.GET_FINANCIAL_INTERMEDIARIES_LIST_PENDING });

    try {
      const { data: intermediariesList } = await getCompanies(params);
      dispatch({
        type: financialBrokerConst.GET_FINANCIAL_INTERMEDIARIES_LIST_FULFILLED,
        payload: intermediariesList,
      });
    } catch (error) {
      console.error('Error while financial intermediaries fetch', error);
      dispatch({
        type: financialBrokerConst.GET_FINANCIAL_INTERMEDIARIES_LIST_REJECTED,
        payload: error.message,
      });
    }
  };
};

// -----------------------------------------------------------------------------------------------
// PRIVATE METHODS
// -----------------------------------------------------------------------------------------------

const dispatchFinancialBrokerData = (dispatch, financialBrokerData, companyUser = null) => {
  const dataToDispatch = buildFinancialBrokerDataToDispatch(financialBrokerData, companyUser);
  // dispatch the financial broker data to the redux form
  dispatchChangeToInvestDetailsForm(
    INVEST_DETAILS_REDUX_OBJECTS_NAMING.financialBroker,
    dataToDispatch,
    dispatch,
  );
  // dispatch the financial broker data to redux
  dispatch(CommonAction.setFinancialBrokerData(dataToDispatch));
  // init the available legal forms
  dispatch(CommonAction.initLegalFormsAndBossStatuses());
};

const buildFinancialBrokerDataToDispatch = financialBroker => {
  const financialBrokerData = {
    id: financialBroker.id,
    name: financialBroker.name,
    registered_country: financialBroker.registered_country,
    crowdfunding_fees: financialBroker.crowdfunding_fees,
    isFromCrowdFunding: financialBroker.isFromCrowdFunding,
    crowdfunding_transfer_ref: financialBroker.transfer_reference,
  };
  return financialBrokerData;
};
