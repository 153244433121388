import { getStore } from '../../store';
import { utils } from '@utocat/catalizr-const-sharing-lib';
import * as _ from 'lodash';
import { getSubdomain } from '../../shared/utils/helper';
import CurrencyFormatter from 'currency-formatter';
import { INVEST_DETAILS_FORM_NAME } from '../../shared/utils/CatalizrFormUtils';

export const createUrlFromDomain = (domain, subUrl) => {
  const hostname = window.location.hostname;
  let url;
  if (hostname.endsWith('local')) {
    url = `http://${domain}.catalizr.local:8080`;
  } else if (hostname.startsWith('preprod')) {
    url = `https://preprod.${domain}.catalizr.eu`;
  } else if (hostname.startsWith('recette')) {
    url = `https://recette.${domain}.catalizr.eu`;
  } else if (hostname.startsWith('qualif')) {
    url = `https://qualif.${domain}.catalizr.eu`;
  } else if (hostname.startsWith('dev')) {
    url = `https://dev.${domain}.catalizr.eu`;
  } else {
    //prod
    url = `https://${domain}.catalizr.eu`;
  }
  url += subUrl;
  return url;
};

const getSupportEnv = () => {
  const hostname = window.location.hostname;
  if (hostname.endsWith('local')) {
    return 'local';
  }
  if (hostname.startsWith('preprod')) {
    return 'pre';
  }
  if (hostname.startsWith('recette')) {
    return 'rec';
  }
  if (hostname.startsWith('qualif')) {
    return 'qua';
  }
  if (hostname.startsWith('dev')) {
    return 'dev';
  }
  return 'prd';
};

// https://blog.shipstone.org/post/alternative-btoa/
const b64EncodeUnicode = str => {
  const uriEncoding = encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
    return String.fromCharCode(parseInt(p1, 16));
  });
  return btoa(uriEncoding);
};

//FIXME disable for now
const generateJsonContentForSupport = () => {
  const state = getStore().getState();
  const env = getSupportEnv();
  const subdomain = getSubdomain();
  const context = {
    navigator: {
      height: window.screen.height,
      width: window.screen.width,
      agent: window.navigator.userAgent,
      location: window.location.href,
    },
    support: {
      userView: `/${env}/investments/profil/${state.user?.email}`,
      investmentView: `/${env}/investments/${subdomain}/${state.investment?.sql_id}`,
      authorInvestView: `/${env}/investments/profil/${state.investment?.author?.email}`,
      investorView: `/${env}/investments/profil/${state.investment?.investor?.email}`,
      companyUserView: `/${env}/investments/profil/${state.investment?.company?.user?.email}`,
      advisorView: `/${env}/investments/profil/${state.investment?.advisor?.email}`,
    },
    // context user + bank
    user: { ...state.user },
    bank: _.omit({ ...state.bank }, ['agencyList', 'bankList', 'legal_notice_link', 'logo']),
    // info on op
    investor: _.omit({ ...state.investor }, ['iban', 'ibans']),
    investment: _.omit({ ...state.investment }, [
      'investmentTypeList',
      'partTypeList',
      'part_type_list',
      'productList',
      'categories',
      'emails',
      'comments',
      ['company', 'iban'],
      'company_iban',
      'iban',
      ['investor', 'ibans'],
      ['investor', 'iban'],
    ]),
    company: _.omit({ ...state.company }, ['legalForms', 'bossStatuses', 'iban']),
    buyer: { ...state.buyer },
    financialBroker: _.omit({ ...state.financialBroker }, ['legalForms', 'bossStatuses', 'iban']),
    // form
    investDetails: _.omit({ ...state.investDetails }, ['partTypesList']),
    // store utils ?
    common: _.omit({ ...state.common }, [
      'banners',
      'currencies',
      'instrumentTypes',
      'potentialPlaces',
    ]),
  };
  return JSON.stringify(context, null, '  ');
};

//FIXME disable for now
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const generateContentForEML = () => {
  const state = getStore().getState();
  const { email, firstname, lastname, type, title, siren, company_name } = state.user;
  let identity = '';
  // For investor : display company name and siren if he is legal person
  if (type === 'investor' && utils.users.isLegalEntity(title)) {
    identity = `${company_name} (${siren})`;
  } else if (firstname && lastname) {
    identity = `${firstname} ${lastname}`;
  }
  const jsonContent = generateJsonContentForSupport();

  // EML content - start
  let emlContent =
    'MIME-Version: 1.0\n' +
    'Date: ' +
    new Date().toUTCString() +
    '\nSubject: Demande de support : \n' +
    'From: \n' +
    'To: Support CATALIZR <support@catalizr.eu>\n' +
    'Content-Type: multipart/mixed; boundary="0000000000005870070609403d6e"\n' +
    '\n' +
    '--0000000000005870070609403d6e\n' +
    'Content-Type: multipart/alternative; boundary="0000000000005870050609403d6c"\n' +
    '\n' +
    '--0000000000005870050609403d6c\n' +
    'Content-Type: text/html; charset="UTF-8"\n' +
    '\n' +
    'Bonjour,<BR>\n' +
    '<BR>\n' +
    "Merci d'utiliser la plateforme CATALIZR. \n" +
    'Le fichier et les informations générés ci-dessous nous servent pour faciliter le traitement de votre demande de support. \n' +
    'Merci de ne pas les modifier.<BR>\n' +
    '<BR>\n' +
    '<b><u>Contexte :</u></b><BR>\n' +
    '<BR>\n';

  // Support contextual data insertion
  if (identity) {
    emlContent = emlContent + 'Utilisateur : ' + identity + '<BR>\n';
  }
  emlContent =
    emlContent +
    'Email du compte : ' +
    email +
    '<BR>\n' +
    "Type d'utilisateur : " +
    type +
    '<BR>\n' +
    'URL : ' +
    window.location +
    '<BR>\n' +
    "Date d'incident : " +
    new Date().toLocaleString('FR');

  // end of mail body + json file declaration
  emlContent =
    emlContent +
    '<BR>\n' +
    '<BR>\n' +
    '<b><u>Description du problème :</u></b><BR>\n' +
    '<BR>\n' +
    'Pouvez-vous nous décrire le problème rencontré ?<BR>\n' +
    "N'hésitez pas à inclure des captures d'écran si le problème est visible depuis l'interface.<BR>\n" +
    '<BR>\n' +
    '<h2>------------------------------ <b>Décrivez votre problème</b> --------------------------------<BR></h2>\n' +
    '<BR>\n' +
    '<BR>\n' +
    '<BR>\n' +
    '<BR>\n' +
    '<BR>\n' +
    '<BR>\n' +
    '<BR>\n' +
    '<BR>\n' +
    '<BR>\n' +
    '<BR>\n' +
    '<BR>\n' +
    '<BR>\n' +
    '<BR>\n' +
    '<BR>\n' +
    '<BR>\n' +
    '<h2>--------------------------------------------------------------------------------------------------<BR></h2>\n' +
    'Nous vous recontacterons pour la résolution de votre problème.<BR>\n' +
    '<BR>\n' +
    'En vous souhaitant une bonne journée,<BR>\n' +
    '<BR>\n' +
    'Cordialement,<BR>\n' +
    '<BR>\n' +
    '--0000000000005870050609403d6c--\n' +
    '--0000000000005870070609403d6e\n' +
    'Content-Type: application/json; name="support.json"\n' +
    'Content-Disposition: attachment; filename="support.json"\n' +
    'Content-Transfer-Encoding: base64\n' +
    'X-Attachment-Id: \n' +
    'Content-ID: <>\n' +
    '\n';

  // Content support json file in base64 + end eml file
  emlContent =
    emlContent + b64EncodeUnicode(jsonContent) + '\n' + '--0000000000005870070609403d6e--\n';

  return emlContent;
};

const generateMailToLink = () => {
  const state = getStore().getState();
  const { email, firstname, lastname, type, title, siren, company_name } = state.user;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const env = getSupportEnv();
  let mailTo = 'mailto:support@catalizr.eu?subject=';
  mailTo += encodeURIComponent('Catalizr - Demande de support de ' + email);
  mailTo += '&body=';
  //
  let identity = '';
  // For investor : display company name and siren if he is legal person
  if (type === 'investor' && utils.users.isLegalEntity(title)) {
    identity = `${company_name} (${siren})`;
  } else if (firstname && lastname) {
    identity = `${firstname} ${lastname}`;
  }
  let body =
    'Bonjour,\n' +
    '\n' +
    "Merci d'utiliser la plateforme Catalizr. \n" +
    '\n' +
    'Pouvez-vous nous décrire le problème rencontré ?\n' +
    "N'hésitez pas à inclure des captures d'écran si le problème est visible depuis l'interface.\n" +
    '\n' +
    '------------------------------ Décrivez votre problème ici ------------------------------\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '--------------------------------------------------------------------------------------------------\n\n' +
    'Les informations générées ci-dessous nous servent pour faciliter le traitement de votre demande de support. \n' +
    'Merci de ne pas les modifier.\n\n' +
    'Contexte :\n\n';

  // Support contextual data insertion
  if (identity) {
    body += 'Utilisateur : ' + identity + '\n';
  }
  body +=
    'Email du compte : ' +
    email +
    "\nType d'utilisateur : " +
    type +
    '\nURL : ' +
    window.location +
    "\nDate d'incident : " +
    new Date().toLocaleString('FR') +
    '\nNavigateur - dimension : ' +
    window.screen.height +
    ' x ' +
    window.screen.width +
    '\nNavigateur - type : ' +
    window.navigator.userAgent +
    '\n';

  const investDataSource = state.form[INVEST_DETAILS_FORM_NAME]?.values?.investment
    ? state.form[INVEST_DETAILS_FORM_NAME].values.investment
    : state.investment;
  const companySource = state.form[INVEST_DETAILS_FORM_NAME]?.values?.company
    ? state.form[INVEST_DETAILS_FORM_NAME].values.company
    : state.investment.company;
  if (state.investment && (state.investment.uuid || state.investment.investment_link)) {
    body +=
      "\nInformation sur l'investissement concerné :\n" +
      '\nType : ' +
      state.investment.operation_type +
      ' - ' +
      state.investment.portfolio_type +
      '\nMontant : ' +
      (investDataSource.nb_part || 'NA') +
      ' * ' +
      (investDataSource.part_amount || 'NA') +
      ' ' +
      (investDataSource.part_currency || 'EUR') +
      ' - ' +
      CurrencyFormatter.format(investDataSource.total_invest, {
        code: investDataSource.part_currency,
      }) +
      '\n Conseiller : ' +
      (state.investment.advisor?.email || state.investment.advisor_email || '-') +
      '\n Investisseur : ' +
      (state.investment.investor?.email || state.investment.investor_email || '-') +
      '\n Représentant entreprise : ' +
      (companySource?.user?.email || '-') +
      '\n Entreprise (SIREN) : (' +
      (companySource?.siren || 'NA') +
      ') ' +
      (companySource?.name || 'NA') +
      '\n Statut : ' +
      (state.investment.status || 'TRANSMIT') +
      '\n Méthode de transmission : ' +
      (state.investment.creation_method || '-') +
      '\n Source : ' +
      (state.investment.source || '-') +
      '\n UUID : ' +
      (state.investment.id || '-') +
      '\n Link UUID : ' +
      (state.investment.uuid || state.investment.investment_link);
  }

  body +=
    '\n\nNous vous recontacterons pour la résolution de votre problème.\n' +
    "\nL'équipe support Catalizr\n";
  mailTo += encodeURIComponent(body);
  return mailTo;
};

export const generateMailSupport = () => {
  // const emlContent = generateContentForEML();
  // const blob = new Blob([emlContent], { type: 'application/octet-stream' });
  // const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  document.body.appendChild(link);
  //link.download = `support_${dayjs(new Date()).format('YYYY-MM-DD_HH-mm-ss')}.eml`;
  link.href = generateMailToLink();
  link.target = '_blank';
  link.click();
  document.body.removeChild(link);
};
