import { get, getAsBlob, post, deleteMethod } from './apicaller';

export const postDocument = (data, config, isDraftMode) =>
  post(
    `${process.env.REACT_APP_BACKEND_URL}/${isDraftMode ? 'uploads-draft' : 'uploads'}`,
    data,
    [],
    config,
  );

export const deleteSharedDocument = (companyId, documentId, data) =>
  deleteMethod(
    `${process.env.REACT_APP_BACKEND_URL}/api/companies/${companyId}/documents/${documentId}`,
    data,
    [],
  );

export const deleteTemplateDocument = (templateUuid, templateDocumentUuid, data, entityPath) => {
  return deleteMethod(
    `${process.env.REACT_APP_BACKEND_URL}/api/${entityPath}-operation-templates/${templateUuid}/documents/${templateDocumentUuid}`,
    data,
    [],
  );
};

// ----------------------------------------------------------------------------------------------------------

/**
 * Retourne la liste des documents requis pour un investissement.
 * retourne toutes les étapes si non renseigné
 * @returns promise contenant la liste des documents associés à l'investissement.
 * @param product - le type de produit
 * @param operation - le type d'opération
 * @param stage
 * @param journey
 * @param bankList
 */
export const retrieveDocuments = (product, operation, stage = 1, journey, bankList) => {
  const params = new URLSearchParams();
  Object.entries({ product, operation, stage, journey, bankList }).forEach(([key, value]) => {
    if (['string', 'object', 'number'].includes(typeof value)) {
      params.set(key, value);
    }
  });
  return get(
    `${process.env.REACT_APP_BACKEND_URL}/api/documenttree/documentslist?${params.toString()}`,
    [],
  );
};

export const retrieveDocumentsArchive = operationId => {
  return getAsBlob(
    `${process.env.REACT_APP_BACKEND_URL}/api/investments/${operationId}/documents/archive`,
    [],
  );
};

/**
 * Retourne la liste des documents associés à un investmentLinkId.
 * @param investmentLinkId
 * @param {object} options optionals parameters for filtering returned documents
 * The accepted parameters are :
 * - signatory {string} : investor OR company
 * - stage {number} : 1 OR 2
 * @returns {promise} containing the documents linked to the investmentLinkId
 */
export const getDocumentsByLinkId = async (investmentLinkId, options = {}) => {
  let endpoint = `investments/documents/${investmentLinkId}`;
  if (options.signatory && !options.stage) {
    endpoint += `?signatory=${options.signatory}`;
  }
  if (options.stage && !options.signatory) {
    endpoint += `?stage=${options.stage}`;
  }
  if (options.signatory && options.stage) {
    endpoint += `?signatory=${options.signatory}&stage=${options.stage}`;
  }
  return get(`${process.env.REACT_APP_BACKEND_URL}/${endpoint}`, []);
};

/**
 *
 * @param queryParams object
 * @param documentUuid string
 * @returns {module:url.URLSearchParams}
 * */

export const retrievePdfLinkDocument = (queryParams, documentUuid) => {
  const params = new URLSearchParams(queryParams);
  const pdfFile = `${
    window.location.origin
  }/document/api/documents/${documentUuid}?${params.toString()}`;
  return pdfFile;
};
