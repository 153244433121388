import React from 'react';
import Big from 'big.js';
import { Enum } from '@utocat/catalizr-const-sharing-lib';
import { change } from 'redux-form';
import _ from 'lodash';

import UtoLoader from '../../components/Loader';
import Alert from '../../components/Alert';
import { isUserBank, isUserCompany } from './JourneyUtils';

// -----------------------------------------------------------------------------------------------
// INVEST-DETAILS
// -----------------------------------------------------------------------------------------------

export const INVEST_DETAILS_FORM_NAME = 'invest-details-form';
export const COMPANY_ATTEST_FORM = 'company-attest-form';
export const INVESTMENTS_BULK_FORM_NAME = 'investments-bulk-form';

// Object listing names of the objects used into the invest-details redux-form
export const INVEST_DETAILS_REDUX_OBJECTS_NAMING = {
  // Entreprise - Titres apportés
  companyKindProvider: 'companyKindProvider',
  // Entreprise pour l'investissement
  company: 'company',
  // PSFP - Prestataire de services de financement participatif (plateforme de crowd, CIP, ...)
  financialBroker: 'financialBroker',
  // Acheteur
  investors: 'investors',
  investor: 'investors[0]',
  coInvestor1: 'investors[0]',
  coInvestor2: 'investors[1]',
  // Vendeur
  seller: 'seller',
  // Investment
  investment: 'investment',
  // User
  user: 'user',
  // investmentReglementary datas (investor form confirmation for example)
  investmentReglementary: 'investmentReglementary',
};

/**
 * Function used to dispatch a change action to the "invest-details" redux form.
 * @param {*} fieldKey the key identifying the field in the redux form (ex: account.title)
 * @param {*} fieldValue the value to set.
 * @param {*} dispatch the redux dispatch function.
 */
export const dispatchChangeToInvestDetailsForm = (fieldKey, fieldValue, dispatch) => {
  dispatchChangeToReduxForm(INVEST_DETAILS_FORM_NAME, fieldKey, fieldValue, dispatch);
};

/**
 * Function used to return a target property values (or all) from a redux object name array into a redux form.
 * @param {string} reduxObjectName the key identifying into the redux form (ex: investors[0])
 * @param {object} formValues the form object from redux form
 * @param {string} propName the target property element among the redux object name array
 * @returns {Array} values of the target property name from redux object name array
 */

export const checkReduxObjectNameArrayValues = (reduxObjectName, formValues, propName = null) => {
  const reduxObjectPropName = reduxObjectName?.split('[')[0] || '';
  if (!formValues || !reduxObjectPropName || !reduxObjectName.includes(reduxObjectPropName)) {
    return [];
  }
  const values = _.get(formValues, reduxObjectPropName, []);

  if (!Array.isArray(values)) {
    return [];
  }

  return values
    .map(item => {
      if (propName) {
        const value = _.get(item, propName, undefined);
        return value !== undefined ? { value } : null;
      } else {
        return item;
      }
    })
    .filter(item => item !== null);
};

// -----------------------------------------------------------------------------------------------
// COMMON METHODS
// -----------------------------------------------------------------------------------------------

// Catch keypress ENTER in <form onKeyPress={keypressHandler}>
export const keypressHandler = event => {
  if (event.target.type !== 'textarea' && event.which === 13 /* Enter */) {
    event.preventDefault();
  }
};

export const formatFloatValue = value => {
  return parseFloat(value.toString().replace(/,/g, '.').replace(/\s/g, ''));
};

export const formatIntValue = value => {
  return parseInt(value.toString().replace(/ /g, '').replace(/\s/g, ''));
};

// -----------------------------------------------------------------------------------------------
// PRIVATE METHODS
// -----------------------------------------------------------------------------------------------

/**
 * Function used to dispatch a change action to a redux form.
 * The redux change action allows to update a field of the form.
 * @param {string} reduxForm ID of the redux form to process
 * @param {string} fieldKey the key identifying the field in the redux form (ex: account.title)
 * @param {string} fieldValue the value to set.
 * @param {func} dispatch the redux dispatch function.
 */
export const dispatchChangeToReduxForm = (reduxForm, fieldKey, fieldValue, dispatch) => {
  if (reduxForm && fieldKey && dispatch) {
    dispatch(change(reduxForm, fieldKey, fieldValue ?? ''));
  }
};

/**
 * Manage state on submit form (pending, fulfilled, rejected) to display loader, information message or error message set in common reducer
 * Mainly used on modal
 * @param {object} statusManager required : object containing isLoading (bool), errorMessage and informationMessage (string)
 * @param {string} margin optional : string provided if we want to apply margin
 * @returns JSX div
 */
export const displayErrorOrSuccessMessageAfterSubmit = (statusManager, margin = '') => {
  const { errorMessage, informationMessage, isLoading } = statusManager;
  let modalFooter;
  if (errorMessage) {
    modalFooter = (
      <Alert severity={'error'} center>
        {errorMessage}
      </Alert>
    );
  } else if (informationMessage) {
    modalFooter = (
      <Alert severity={'success'} center>
        {informationMessage}
      </Alert>
    );
  } else if (isLoading) {
    modalFooter = (
      <div className={`modal__error ${margin}`}>
        Envoi en cours...
        <UtoLoader />
      </div>
    );
  }
  return modalFooter;
};

export const checkFormMetadataDisabled = (sectionName, fieldName, formMetadata) => {
  if (!Array.isArray(formMetadata) || formMetadata.length === 0) {
    return false;
  }
  const fieldMetadata = formMetadata.find(
    predicate => predicate.field_name === fieldName && predicate.section_name === sectionName,
  );
  if (!fieldMetadata) {
    return false;
  }
  switch (fieldMetadata.source) {
    case 'bank_template':
    case 'papi':
      return !isUserBank();
    case 'company_template':
      return !isUserCompany();
    default:
      return true;
  }
};

export const getTotalAmount = props => {
  const nbPart = formatIntValue(props?.formValues?.investment?.nb_part ?? 0);
  const partAmount = formatFloatValue(props?.formValues?.investment?.part_amount ?? 0);
  const distributionFeeRate = formatFloatValue(
    props?.formValues?.investment?.distribution_fee ?? 0,
  );
  const crowdfunding_fees = formatFloatValue(
    props?.formValues?.financialBroker?.crowdfunding_fees ?? 0,
  );
  const entryCommissionRate = formatFloatValue(
    props?.formValues?.investment?.entry_commission ?? 0,
  );
  const isFromCrowdFunding =
    props?.formValues?.financialBroker?.isFromCrowdFunding === Enum.PDF.FieldOptionValue.TRUE;
  const amountDetails = computeAmountWithFeesForOperation(
    nbPart,
    partAmount,
    isFromCrowdFunding,
    crowdfunding_fees,
    distributionFeeRate,
    entryCommissionRate,
  );
  return amountDetails.total_amount;
};

/**
 * compute total amount and details
 * @param quantity {number | null} - security quantity
 * @param unitValue {number | null} - unit value of security
 * @param isFromCrowdFunding {boolean} - fees for crowdfunding platforms
 * @param crowdFundingFees {number | null} - fees for crowdfunding platforms
 * @param distributionRate {number | null} - retrocession rate for distributor
 * @param entryCommissionRate {number | null} - entry commission rate for bank
 */
export const computeAmountWithFeesForOperation = (
  quantity,
  unitValue,
  isFromCrowdFunding,
  crowdFundingFees,
  distributionRate,
  entryCommissionRate,
) => {
  const precisionRound = number => {
    if (Number.isNaN(number) || number === undefined || number === null) {
      return 0;
    }
    return Big(Big(number).toFixed(2)).toNumber();
  };

  let bigSecurityAmount = Big(quantity && !isNaN(quantity) ? quantity : 0).times(
    unitValue && !isNaN(unitValue) ? unitValue : 0,
  );
  let bigCrowdfunding = 0;
  if (isFromCrowdFunding) {
    bigCrowdfunding = Big(crowdFundingFees && !isNaN(crowdFundingFees) ? crowdFundingFees : 0);
  }
  let bigCommission = 0;
  if (entryCommissionRate > 0) {
    // Phitrust - 1% de commission pour Fonds de dotation Phitrust
    bigCommission = bigSecurityAmount
      .times(entryCommissionRate && !isNaN(entryCommissionRate) ? entryCommissionRate : 0)
      .div(100);
  }
  let bigDistributorFees = 0;
  if (distributionRate > 0) {
    bigDistributorFees = bigSecurityAmount
      .times(distributionRate && !isNaN(distributionRate) ? distributionRate : 0)
      .div(100);
  }
  let bigTotal = bigSecurityAmount.add(bigCrowdfunding).add(bigDistributorFees).add(bigCommission);
  const resultData = {
    security_amount: precisionRound(bigSecurityAmount),
    crowdfunding_fees: precisionRound(bigCrowdfunding),
    distributor_fees: precisionRound(bigDistributorFees),
    entry_commission: precisionRound(bigCommission),
    total_amount: precisionRound(bigTotal),
  };
  const diff = Big(resultData.total_amount)
    .minus(resultData.security_amount)
    .minus(resultData.crowdfunding_fees)
    .minus(resultData.distributor_fees)
    .minus(resultData.entry_commission)
    .toNumber();
  if (diff > 0) {
    /**
     * in case of centime diff when adding all rounding values
     * it will be redistributed to commission > distributor fees > crowd > security (should not happen)
     *
     * for exemple
     *   - raw value : 12,444 + 1,222 + 1,222 + 1.222 = 16.11
     *   - rounded value : 12,44 + 1,22 + 1,22 + 1,22 = 16.1 -> 1 centime de diffs
     */
    if (resultData.entry_commission > 0) {
      resultData.entry_commission = resultData.entry_commission + diff;
    } else if (resultData.distributor_fees > 0) {
      resultData.distributor_fees = resultData.distributor_fees + diff;
    }
    // should not happen if just security amount and crowdfunding fees
  }
  return resultData;
};

export const isNotEmpty = fieldValue =>
  (!!fieldValue && fieldValue !== null && fieldValue !== '' && fieldValue.length > 0) ||
  typeof fieldValue === 'boolean';

export const isNotEmptyDate = fieldValue =>
  !!fieldValue && fieldValue !== null && fieldValue !== '' && typeof fieldValue === 'object';

export const isNewSiren = (formValues, reduxObjectName) =>
  formValues &&
  formValues[reduxObjectName] &&
  isNotEmpty(formValues[reduxObjectName].siren) &&
  !formValues[reduxObjectName].data_from_insee;
