export const GET_COMPANY_DETAILS = 'COMPANY_GET_DETAILS';
export const GET_COMPANY_DETAILS_PENDING = 'COMPANY_GET_DETAILS_PENDING';
export const GET_COMPANY_DETAILS_FULFILLED = 'COMPANY_GET_DETAILS_FULFILLED';
export const GET_COMPANY_DETAILS_REJECTED = 'COMPANY_GET_DETAILS_REJECTED';
export const RESET_ID_COMPANY = 'RESET_ID_COMPANY';
export const RESET_COMPANY_DATA = 'RESET_COMPANY_DATA';

export const GET_COMPANY_POTENTIAL_PLACES = 'COMPANY_GET_COMPANY_POTENTIAL_PLACES';
export const GET_COMPANY_POTENTIAL_PLACES_PENDING = 'COMPANY_GET_COMPANY_POTENTIAL_PLACES_PENDING';
export const GET_COMPANY_POTENTIAL_PLACES_FULFILLED =
  'COMPANY_GET_COMPANY_POTENTIAL_PLACES_FULFILLED';
export const GET_COMPANY_POTENTIAL_PLACES_REJECTED =
  'COMPANY_GET_COMPANY_POTENTIAL_PLACES_REJECTED';

export const SET_COMPANY_DATA = 'COMPANY_SET_COMPANY_DATA';
export const SET_COMPANY_KIND_PROVIDER_DATA =
  'COMPANY_KIND_PROVIDER_SET_COMPANY_KIND_PROVIDER_DATA';
export const SET_COMPANY_POTENTIAL_PLACES = 'COMPANY_SET_COMPANY_POTENTIAL_PLACES';
export const SET_COMPANY_BOSS_PHONE = 'COMPANY_SET_COMPANY_BOSS_PHONE';
export const SET_COMPANY_USER_PHONE = 'COMPANY_SET_COMPANY_USER_PHONE';
export const SET_COMPANY_SIREN = 'COMPANY_SET_SIREN';
export const SET_COMPANIES_LEGAL_FORMS = 'COMPANIES_SET_LEGAL_FORMS_DATA';
export const SET_COMPANIES_BOSS_STATUS = 'COMPANIES_SET_BOSS_STATUS_DATA';
export const SET_SELECTED_COMPANY = 'SET_SELECTED_COMPANY';
export const RESET_SELECTED_COMPANY = 'RESET_SELECTED_COMPANY';
export const GET_COMPANY_DATA_FROM_INSEE = 'GET_COMPANY_DATA_FROM_INSEE';
export const GET_COMPANY_DATA_FROM_INSEE_PENDING = 'GET_COMPANY_DATA_FROM_INSEE_PENDING';
export const GET_COMPANY_DATA_FROM_INSEE_FULFILLED = 'GET_COMPANY_DATA_FROM_INSEE_FULFILLED';
export const GET_COMPANY_DATA_FROM_INSEE_REJECTED = 'GET_COMPANY_DATA_FROM_INSEE_REJECTED';
export const SET_COMPANY_AUTOCOMPLETE_INSEE_SIREN = 'SET_COMPANY_AUTOCOMPLETE_INSEE_SIREN';
export const SET_COMPANY_CEASED_INSEE_SIREN = 'SET_COMPANY_CEASED_INSEE_SIREN';
export const SET_COMPANY_DATA_FROM_KBIS = 'SET_COMPANY_DATA_FROM_KBIS';
