// Const
import * as commonConst from '../actions/const/common.const';
import * as financialBrokerConst from '../actions/const/financial-broker.const';
import * as landingConst from '../../pages/landing/const/landing.const';
import * as investDetailsConst from '../../pages/invest-details/actions/invest-details.const';

export const initState = {
  crowdfunding_fees: 0,
  name: '',
  id: '',
  // TODO wait to use iso code 2
  registered_country: 'France',
  financialIntermediariesList: [],
  loading: false,
  error: null,
};

const financialBroker = (state = initState, action) => {
  switch (action.type) {
    case commonConst.CLEAR_LOGOUT:
    case commonConst.CLEAR_REDUX:
    case commonConst.CLEAR_LOGOUT_PENDING:
    case landingConst.INIT_VIEW_PENDING:
    case landingConst.START_NEW_TEMPLATE_PENDING:
    case commonConst.INIT_STATE:
      return initState;

    case investDetailsConst.SET_FINANCIAL_BROKER_DATA:
    case financialBrokerConst.SET_FINANCIAL_BROKER_DATA:
      return { ...state, ...action.data };

    case financialBrokerConst.RESET_FINANCIAL_BROKER_DATA:
      return initState;

    case financialBrokerConst.GET_FINANCIAL_INTERMEDIARIES_LIST_PENDING:
      return { ...state, loading: true, error: null };
    case financialBrokerConst.GET_FINANCIAL_INTERMEDIARIES_LIST_FULFILLED:
      return { ...state, financialIntermediariesList: action.payload, loading: false };
    case financialBrokerConst.GET_FINANCIAL_INTERMEDIARIES_LIST_REJECTED:
      return { ...state, error: action.payload, loading: false };

    default:
      return state;
  }
};

export default financialBroker;
