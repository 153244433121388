import * as dashboardConst from '../../common/const/dashboard.const';
import queryString from 'query-string';

// Actions
import {
  getCounters,
  getUserInvestmentsByCurrentTab,
  setCurrentTab,
} from '../../common/actions/dashboard.actions';
import { setOriginalJourneyId } from '../../../../redux/actions';
import { resetInvestorState } from '../../../../shared/actions/actions/investor.actions';
import { dashboardTab } from '../../../../shared/utils/CatalizrConstants';
import { isFeatureEnabled } from '../../../../shared/utils/CatalizrUtils';
import { getInvestmentTypeList } from '../../../../shared/api/investment.api';
import { initInvestmentType } from '../../../../shared/actions/actions/investment.actions';
import { fetchAgencyList } from '../../../../shared/actions/actions/bank.actions';
import { FEATURE_FLAG } from '../../../../shared/utils/featureFlag';
import { resetFinancialBrokerData } from '../../../../shared/actions/actions/financial-broker.actions';

export const initViewNewOperation = (subDomain = null) => {
  return dispatch => {
    // INIT MODAL NEW OPERATION
    getInvestmentTypeList(null, null, subDomain).then(investmentTypeListResp => {
      // Get operation types list with product types allowed
      dispatch(initInvestmentType(investmentTypeListResp.data.data));
      // Get agency list if it is allowed in bank init
      if (isFeatureEnabled('display.agency_list')) {
        dispatch(fetchAgencyList());
      }
    });
  };
};

const refreshReduxData = () => ({ type: dashboardConst.REFRESH_REDUX_DATA });

export const initView = (subDomain = null) => {
  return async (dispatch, getState) => {
    const { tabIndex } = getState().dashboard;
    const hasOperationModule = isFeatureEnabled(FEATURE_FLAG.module_operations);
    const hasOSTModule = isFeatureEnabled(FEATURE_FLAG.module_ost);
    dispatch(resetFinancialBrokerData());
    dispatch(refreshReduxData());
    dispatch(setOriginalJourneyId(''));
    dispatch(resetInvestorState());

    // If tabIndex is present in path, we redirect bank to the correct tab
    if (location.search !== '') {
      let values = queryString.parse(location.search);
      if (tabIndex !== parseInt(values.tabIndex)) {
        dispatch(setCurrentTab(parseInt(values.tabIndex)));
      }
      // We remove search params after redirection
      window.history.pushState(null, null, window.location.pathname);
    }

    // INIT DASHBOARD OPERATIONS
    const getDashboardCounters = dispatch(getCounters());
    const getOperation = dispatch(getUserInvestmentsByCurrentTab());

    if (hasOSTModule && !hasOperationModule && tabIndex !== dashboardTab.CORPORATE_ACTIONS) {
      dispatch(setCurrentTab(dashboardTab.CORPORATE_ACTIONS));
    }

    // INIT MODAL NEW OPERATION
    if (hasOperationModule) {
      dispatch(initViewNewOperation(subDomain));
    }

    await Promise.all([getDashboardCounters, getOperation]);
  };
};
