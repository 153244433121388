import React from 'react';
import PropTypes from 'prop-types';
import IconNotesSvg from './svg/icon-notes-svg';
import './styles.scss';

const IconNotes = props => {
  const { title, alt, width, height, onClick } = props;

  return (
    <div className="notes" data-testid="release_notes" onClick={onClick}>
      <IconNotesSvg className="notes__icon" title={title} alt={alt} width={width} height={height} />
    </div>
  );
};

IconNotes.propTypes = {
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default IconNotes;
