export const BULK_OPERATIONS_MAX_SELECTED = 20;
export const BULK_OPERATIONS_SET_CURRENT_PAGE = 'BULK_OPERATIONS_SET_CURRENT_PAGE';
export const BULK_OPERATIONS_SET_PER_PAGE = 'BULK_OPERATIONS_SET_PER_PAGE';
export const BULK_OPERATIONS_SET_CURRENT_SORT = 'BULK_OPERATIONS_SET_CURRENT_SORT';

export const BULK_GET_DYNAMIC_FILTERS = 'BULK_GET_DYNAMIC_FILTERS';
export const BULK_GET_DYNAMIC_FILTERS_PENDING = 'BULK_GET_DYNAMIC_FILTERS_PENDING';
export const BULK_GET_DYNAMIC_FILTERS_FULFILLED = 'BULK_GET_DYNAMIC_FILTERS_FULFILLED';
export const BULK_GET_DYNAMIC_FILTERS_REJECTED = 'BULK_GET_DYNAMIC_FILTERS_REJECTED';

export const BULK_POST_FILTERS = 'BULK_POST_FILTERS';
export const BULK_POST_FILTERS_PENDING = 'BULK_POST_FILTERS_PENDING';
export const BULK_POST_FILTERS_FULFILLED = 'BULK_POST_FILTERS_FULFILLED';
export const BULK_POST_FILTERS_REJECTED = 'BULK_POST_FILTERS_REJECTED';

export const BULK_GET_DOCUMENTS_LIST = 'BULK_GET_DOCUMENTS_LIST';
export const BULK_GET_DOCUMENTS_LIST_PENDING = 'BULK_GET_DOCUMENTS_LIST_PENDING';
export const BULK_GET_DOCUMENTS_LIST_FULFILLED = 'BULK_GET_DOCUMENTS_LIST_FULFILLED';
export const BULK_GET_DOCUMENTS_LIST_REJECTED = 'BULK_GET_DOCUMENTS_LIST_REJECTED';

export const BULK_SET_OPERATIONS_LIST = 'BULK_SET_OPERATIONS_LIST';
export const BULK_SET_FILTERS_SELECTED = 'BULK_SET_FILTERS_SELECTED';
export const BULK_RESET_OPERATIONS_LIST = 'BULK_RESET_OPERATIONS_LIST';
export const BULK_SET_SELECT_ALL_CHECKBOXES = 'BULK_SET_SELECT_ALL_CHECKBOXES';
export const BULK_SET_SELECTED_LIST = 'BULK_SET_SELECTED_LIST';
export const BULK_INIT_VIEW = 'BULK_INIT_VIEW';
export const BULK_SET_STEP = 'BULK_SET_STEP';

export const BULK_CREATE_ESIGNATURE = 'BULK_CREATE_ESIGNATURE';
export const BULK_CREATE_ESIGNATURE_PENDING = 'BULK_CREATE_ESIGNATURE_PENDING';
export const BULK_CREATE_ESIGNATURE_FULFILLED = 'BULK_CREATE_ESIGNATURE_FULFILLED';
export const BULK_CREATE_ESIGNATURE_REJECTED = 'BULK_CREATE_ESIGNATURE_REJECTED';

export const BULK_SUBMIT_FORM = 'BULK_SUBMIT_FORM';
export const BULK_SUBMIT_FORM_PENDING = 'BULK_SUBMIT_FORM_PENDING';
export const BULK_SUBMIT_FORM_FULFILLED = 'BULK_SUBMIT_FORM_FULFILLED';
export const BULK_SUBMIT_FORM_REJECTED = 'BULK_SUBMIT_FORM_REJECTED';

export const BULK_GENERATE_SIGNATURE_ENVELOPE_PENDING = 'BULK_GENERATE_SIGNATURE_ENVELOPE_PENDING';
export const BULK_GENERATE_SIGNATURE_ENVELOPE_FULFILLED =
  'BULK_GENERATE_SIGNATURE_ENVELOPE_FULFILLED';
export const BULK_GENERATE_SIGNATURE_ENVELOPE_REJECTED =
  'BULK_GENERATE_SIGNATURE_ENVELOPE_REJECTED';
export const BULK_DISPLAY_REDIRECTION = 'BULK_DISPLAY_REDIRECTION';
