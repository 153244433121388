import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import IconCircleWarningSvg from './svg/icon-circle-warning-svg';

const IconCircleWarning = props => {
  const { title, alt, width, height } = props;

  return (
    <div className="circle-warning">
      <IconCircleWarningSvg title={title} alt={alt} width={width} height={height} />
    </div>
  );
};

IconCircleWarning.propTypes = {
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default IconCircleWarning;
