import { formatFilters } from '../../pages/dashboard/common/api/dashboard.api';
import { buildUrlQuery } from '../utils/helper';
import { get, post, put } from './apicaller';

export const getCompanyById = companyId =>
  get(`${process.env.REACT_APP_BACKEND_URL}/api/companies/${companyId}`, []);

/**
 * Retrieve companies
 * @param {object} params contains filters, order_by, page, per_page and sort
 * @returns {array} company informations retrieved from requested field
 */

export const getCompanies = params => {
  const { filters, orderBy = 'name', sort = 'asc' } = params;
  const filtersParsed = {
    ...formatFilters(filters),
  };
  return get(
    buildUrlQuery(`${process.env.REACT_APP_BACKEND_URL}/api/companies`, {
      filter: JSON.stringify(filtersParsed),
      order_by: orderBy,
      sort: sort,
    }),
    [],
  );
};

export const getCompaniesListByName = (name, sirenAvailable) => {
  let url = `${process.env.REACT_APP_BACKEND_URL}/api/companies/search`;
  if (sirenAvailable !== undefined) {
    url += `?siren_available=${sirenAvailable}`;
  }
  return post(url, { name }, []);
};

export const getCompaniesListBySiren = siren =>
  post(`${process.env.REACT_APP_BACKEND_URL}/api/companies/search/siren`, { query: siren }, []);

export const getInseeCompanyDatasBySiren = siren =>
  post(
    `${process.env.REACT_APP_BACKEND_URL}/api/companies/search/insee/siren`,
    { query: siren },
    [],
  );

export const slackToPo = (investmentId, phone, siren) =>
  post(
    `${process.env.REACT_APP_BACKEND_URL}/slack/po`,
    {
      investmentId: investmentId,
      phoneNumber: phone,
      siren: siren,
    },
    [],
  );

export const updateUserCompany = (sqlId, body) =>
  put(`${process.env.REACT_APP_BACKEND_URL}/api/companies/users/${sqlId}`, body, []);

export const updateCompany = (sqlId, body) =>
  put(`${process.env.REACT_APP_BACKEND_URL}/api/companies/${sqlId}`, body, []);

/**
 * Retourne la liste contenant les formes juridiques des entreprises.
 */
export const retrieveCompaniesLegalForms = async () =>
  get(`${process.env.REACT_APP_BACKEND_URL}/legal-forms`, []);

/**
 * Retrieve sirens or company names from an email company
 * @param {string} email the email to check in where condition in table users
 * @returns {object} company informations retrieved from requested field
 */

export const getCompaniesIdentificationInfosByEmail = async email => {
  return get(
    `${process.env.REACT_APP_BACKEND_URL}/api/companies/identification-infos/email/search?email=${email}`,
    [],
  );
};

/**
 * Retourne la liste contenant les statuts possibles du dirigeant.
 */
export const retrieveCompaniesBossStatus = async () =>
  get(`${process.env.REACT_APP_BACKEND_URL}/boss-status`, []);

/**
 * Retourne la liste des entreprises ayant au moins une opération en cours correspondant au type d'opération donné
 * et dont l'opération nécessite l'upload du document donné
 */
export const getCompaniesListMissingDocument = (operationType, documentType) =>
  get(
    `${process.env.REACT_APP_BACKEND_URL}/companies/operation-type/${operationType}/missingdocument/${documentType}`,
    [404],
  );

export const postErrorTemplate = data =>
  post(
    `${process.env.REACT_APP_BACKEND_URL}/api/investments/errors`,
    {
      ...data,
    },
    [],
  );

export const getIsinSharesByIsinOrCompany = (isin, companyId) =>
  post(
    `${process.env.REACT_APP_BACKEND_URL}/api/companies/search/isin-shares`,
    { isin, companyId },
    [],
  );
