import React from 'react';
import { createIntl, createIntlCache } from 'react-intl';
import { intlFlattenTexts } from './shared/utils/intl-locale';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.locale('fr');
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);

const intlCache = createIntlCache();

const loadMessagesInLocale = async locale => {
  const messages = await import(`./locales/${locale}.json`);
  return intlFlattenTexts(messages.default);
};

const defaultLocale = 'fr';
let defaultMessages;
const initDefaultMessages = async () => {
  defaultMessages = await loadMessagesInLocale(defaultLocale);
};
initDefaultMessages();
const defaultRichTextElements = {
  br: <br />,
  aSupport: chunks => <a href="mailto:support@catalizr.eu">{chunks}</a>,
  aChangelog: chunks => (
    <a href="https://www.utocat.com/nouveautes" target="_blank" rel="noopener noreferrer">
      {chunks}
    </a>
  ),
  b: chunks => <b>{chunks}</b>,
  div: chunks => <div>{chunks}</div>,
  p: chunks => <p>{chunks}</p>,
  strong: chunks => <strong>{chunks}</strong>,
  span: chunks => <span>{chunks}</span>,
  spanUnderlined: chunks => <span className="text--underlined">{chunks}</span>,
};

export let localizedCollator = new Intl.Collator('fr');

export let intl = createIntl(
  {
    defaultLocale,
    defaultRichTextElements,
    locale: defaultLocale,
    messages: defaultMessages,
  },
  intlCache,
);

export const updateIntl = async locale => {
  const messages = await loadMessagesInLocale(locale);
  localizedCollator = new Intl.Collator(locale);
  dayjs.locale(locale);
  intl = createIntl(
    {
      defaultLocale,
      defaultRichTextElements,
      locale,
      messages,
    },
    intlCache,
  );
  return intl;
};

// for test only
export const resetIntl = () => {
  intl = createIntl(
    {
      defaultLocale,
      defaultRichTextElements,
      locale: defaultLocale,
      messages: {},
      onError: () => {},
      onWarn: () => {},
    },
    intlCache,
  );
};
