// Const
import * as investmentConst from '../actions/const/investment.const';
import * as landingConst from '../../pages/landing/const/landing.const';
import * as investDetailsConst from '../../pages/invest-details/actions/invest-details.const';
import * as commonConst from '../actions/const/common.const';
import * as dashboardConst from '../../pages/dashboard/common/const/dashboard.const';
import { stringToDate } from '../utils/helper';
import dayjs from 'dayjs';

const INVESTMENT_CATEGORY_NONE = 'Aucune';

const initState = {
  id: '',
  investmentTypeList: [],
  partTypeList: [],
  currentInvestmentType: '',
  investmentDisposalMode: '',
  productList: [],
  currentProduct: '',
  currentCompany: {},
  documentsUpload: [],
  bank_address: '',
  bank_name: '',
  categories: [INVESTMENT_CATEGORY_NONE],
  category: INVESTMENT_CATEGORY_NONE,
  nb_part: null,
  part_amount: null,
  part_type: '',
  part_level: 100,
  entry_commission: 0,
  distribution_fee: 0,
  distribution_fee_bank_name: '',
  close_date: '',
  ownership_date: '',
  iban: '',
  updatedAt: '',
  status: '',
  emitted_payment_date: null,
  comments: [],
  investment_link: null,
  source: null,
  currentComment: '',
  emails: [],
  compartments: [],
  nominativeStatuses: [],
  distribution_fee_banks: [],
  isin: {},
  advisor_in_charge_email: null,
  error: false,
  transfer_reference: '',
  eligibility: 'eligibility_on',
};

const investment = (state = initState, action) => {
  switch (action.type) {
    case commonConst.INIT_STATE:
    case commonConst.CLEAR_LOGOUT:
    case commonConst.CLEAR_REDUX:
    case commonConst.CLEAR_LOGOUT_PENDING:
    case landingConst.START_NEW_TEMPLATE_PENDING:
      return {
        ...initState,
        investmentDisposalMode: state.investmentDisposalMode,
      };
    case landingConst.INIT_VIEW_PENDING:
      return {
        ...initState,
        // keep comment for forward mode
        currentComment: state.currentComment,
        investmentDisposalMode: state.investmentDisposalMode,
      };
    case investmentConst.INIT_INVESTMENT:
      return {
        ...state,
        ...action.investment,
        documentsUpload: action.investment.documents,
        currentInvestmentType: action.investment.operation_type,
        currentProduct: action.investment.portfolio_type,
        close_date: action.investment.close_date ? stringToDate(action.investment.close_date) : '',
        ownership_date: action.investment.ownership_date
          ? stringToDate(action.investment.ownership_date)
          : '',
        partTypeList: action.investment.part_type_list,
      };

    case investmentConst.INIT_INVESTMENT_TYPE:
      // currentInvestemntType is empty to force advisor / backoffice to choose in dropdown
      return {
        ...state,
        investmentTypeList: action.investmentTypeList,
        currentInvestmentType: '',
      };

    case investmentConst.SET_INVESTMENT_TYPE_LIST:
      return { ...state, investmentTypeList: action.investmentTypeList };

    case investmentConst.SET_CURRENT_INVESTMENT_TYPE:
      return { ...state, currentInvestmentType: action.investmentType };

    case investmentConst.SET_CURRENT_INVESTMENT_DISPOSAL_MODE:
      return { ...state, investmentDisposalMode: action.investmentDisposalMode };

    case investmentConst.INIT_PRODUCT:
      // currentProduct is empty to force advisor / backoffice to choose in dropdown except if we are in edition mode
      return {
        ...state,
        productList: action.productList,
        currentProduct: state.currentProduct,
      };

    case investmentConst.SET_PRODUCT_LIST:
      return { ...state, productList: action.productList };

    case investmentConst.SET_CURRENT_PRODUCT:
      return { ...state, currentProduct: action.product };

    case investmentConst.SET_CURRENT_COMPANY: {
      return { ...state, currentCompany: action.company };
    }

    case investmentConst.SET_PART_LEVEL:
      return { ...state, part_level: Number(action.partLevel) };

    case investmentConst.SET_CLOSE_DATE:
      return { ...state, close_date: action.investDate };

    case investmentConst.SET_REAL_ESTATE_DATE:
      return { ...state, ownership_date: action.ownershipDate };

    case landingConst.START_NEW_OPERATION_FULFILLED: {
      return {
        ...state,
        ...action.investment,
        currentInvestmentType: action.investment.operation_type,
        currentProduct: action.investment.portfolio_type,
        currentCompany: action.investment.currentCompany,
      };
    }

    case landingConst.INIT_INVESTMENT: {
      return {
        ...state,
        ...action.investment,
        currentInvestmentType: action.investment.operation_type,
        currentProduct: action.investment.portfolio_type,
        documentsUpload: action.investment.documents,
        partTypeList: action.investment.part_type_list,
      };
    }

    case investDetailsConst.SET_INVESTMENT_DATA:
      return { ...state, ...action.investment };

    case investmentConst.INIT_INVESTMENT_BANK:
      return {
        ...state,
        bank_name: action.investor.bank_name,
        iban: action.investor.iban,
      };

    case investmentConst.SET_STATUS_FULLFILED:
      return {
        ...state,
        status: action.status,
      };

    case investmentConst.CONFIRM_PAYMENT_RELEASE_FULLFILED:
      return {
        ...state,
        status: action.status,
        emitted_payment_date: Date.now(),
      };
    case dashboardConst.GET_OPERATION_COMMENTS_FULFILLED:
      return {
        ...state,
        comments: action.payload,
      };
    case dashboardConst.GET_OPERATION_COMMENTS_PENDING:
    case dashboardConst.GET_OPERATION_COMMENTS_REJECTED:
      return {
        ...state,
        comments: [],
      };
    case dashboardConst.POST_OPERATION_COMMENT_FULFILLED: {
      // Display new comment instantly after submit
      let updateComments = state.comments;
      let publishNewComment = {
        comment: action.payload.comment,
        user_email: action.payload.user_email,
        createdAt: dayjs(),
      };
      updateComments.unshift(publishNewComment);
      return {
        ...state,
        comments: updateComments,
      };
    }

    case dashboardConst.POST_OPERATION_COMMENT_PENDING:
    case dashboardConst.POST_OPERATION_COMMENT_REJECTED:
      return {
        ...state,
      };
    case investmentConst.SET_INVESTMENT_LINK_ID:
      return {
        ...state,
        investment_link: action.idLink,
      };
    case dashboardConst.SET_CURRENT_COMMENT:
      return {
        ...state,
        currentComment: action.comment,
      };
    case dashboardConst.RESET_CURRENT_COMMENT_AFTER_SUBMIT:
      return {
        ...state,
        currentComment: '',
      };
    case dashboardConst.GET_EMAILS_FULFILLED:
      return {
        ...state,
        emails: action.payload,
      };
    case dashboardConst.GET_EMAILS_REJECTED:
    case dashboardConst.GET_EMAILS_PENDING:
      return {
        ...state,
        emails: [],
      };
    case dashboardConst.REFRESH_REDUX_DATA:
      return {
        ...initState,
      };
    case investmentConst.SET_COMPARTMENTS:
      return {
        ...state,
        compartments: action.compartments.map(item => item.name),
      };
    case investmentConst.SET_NOMINATIVE_STATUS:
      return {
        ...state,
        nominativeStatuses: action.nominativeStatuses.map(item => item.name),
      };
    case investmentConst.SET_DISTRIBUTION_FEE_BANKS:
      return {
        ...state,
        distributionFeeBanks: action.distributionFeeBanks,
      };
    case investmentConst.SET_INVESTMENT_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case investmentConst.SET_INVESTMENT_CATEGORIES:
      return {
        ...state,
        categories: [INVESTMENT_CATEGORY_NONE, ...action.payload],
      };
    case investmentConst.SET_ADVISOR_IN_CHARGE:
      return {
        ...state,
        advisor_in_charge_email: action.payload,
      };
    case investmentConst.GET_INVESTMENT_LINKS_DETAILS: {
      return {
        ...state,
        ...action.investment,
        isLoading: false,
      };
    }

    default:
      return state;
  }
};

export default investment;
