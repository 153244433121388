import * as React from 'react';
import PropTypes from 'prop-types';

const IconCircleWarningSvg = ({ alt, title, width = '1em', height = '1em', ...props }) => {
  return (
    <svg
      width={width}
      height={height}
      fill="currentColor"
      viewBox="0 0 24 24"
      aria-label={alt}
      {...props}
    >
      <title>{title}</title>
      <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-7v2h2v-2h-2zm0-8v6h2V7h-2z" />
    </svg>
  );
};

IconCircleWarningSvg.propTypes = {
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default IconCircleWarningSvg;
