import fileDownload from 'js-file-download';
import { Enum } from '@utocat/catalizr-const-sharing-lib';

import dayjs from 'dayjs';
import * as dashboardConst from '../const/dashboard.const';

// API
import {
  deleteCompanyShareholdingManualInsertion,
  deleteDocumentSharesValuations,
  exportFundManagement,
  getBackofficeSharesValuationList,
  getComments,
  getCompanyShareholdingList,
  getDashboardCounters,
  getEmailByLinkId,
  getInvestmentFurthestStep,
  getInvestmentLatestStep,
  getInvestmentsExportForFund,
  getInvestmentsInProgress,
  getInvestmentsInProgressExport,
  getInvestmentsSuggested,
  getInvestorSharesValuationPositionList,
  getOperationCancelledByUserType,
  getOperationCancelledByUserTypeExport,
  getPositionsDetailsByCompanyId,
  getPositionsDetailsExport,
  getShareholdingDetailsByInvestorId,
  getShareholdingExport,
  getShareholdingInvestorWalletExport,
  getSharesValuationDetailsByCompanyId,
  getSharesValuationDetailsByCompanyIdExport,
  getSharesValuationExport,
  getTransmitsList,
  getTransmitsListExport,
  getWallet,
  getWalletExport,
  postComment,
  postCompanyShareholdingManualInsertion,
  postCompanyUpdatedPositions,
  postCompanyUpdatedSharesValuation,
  putUpdateOperationFlagStatus,
  putUpdateSuggestedOperation,
  resendEmail,
  retrieveFundList,
} from '../api/dashboard.api';
import { getAllTemplates } from '../../../../shared/api/template.api';

// Actions
import { setInvestmentLinkId } from '../../../../shared/actions/actions/investment.actions';
import {
  clearMessagesEveryXTime,
  setErrorMessage,
} from '../../../../shared/actions/actions/common.actions';
import { getStore } from '../../../../store';
import { getOperationFilters } from '../../../../shared/actions/actions/investment.actions';
import * as investmentConst from '../../../../shared/actions/const/investment.const';

// Utils
import {
  CREATION_METHOD,
  DASHBOARD_STATUSES,
  OPERATION_TYPE_DISPOSAL,
  dashboardTab,
} from '../../../../shared/utils/CatalizrConstants';
import { formatFloatValue } from '../../../../shared/utils/CatalizrFormUtils';
import {
  getEntityPathApiFromUserType,
  getEntityPathFromUserType,
} from '../../../../shared/utils/user';
import { isFeatureEnabled } from '../../../../shared/utils/CatalizrUtils';
import { TABLE_NAME } from '../../../../shared/utils/table';
import { isUserInvestor } from '../../../../shared/utils/JourneyUtils';

/**
 |-------------------------------------------------------------------
 | Editing suggested operations from dashboard modal new operation
 | Operations can be edited company
 |-------------------------------------------------------------------
 */

const editSuggestedOperationPending = () => ({
  type: dashboardConst.EDIT_SUGGESTED_OPERATION_PENDING,
});

const editSuggestedOperationFulfilled = () => ({
  type: dashboardConst.EDIT_SUGGESTED_OPERATION_FULFILLED,
});

const editSuggestedOperationRejected = errorMessage => ({
  type: dashboardConst.EDIT_SUGGESTED_OPERATION_REJECTED,
  errorMessage,
});

/**
 * Function to call for updating a transmit operation
 * @param {string} investmentDraftLinkId mongoId of the operation to process
 * @param {object} datas containing data to update : operation_type, portfolio_type, investor_email, advisor_email
 */
export const editSuggestedOperation = (investmentDraftLinkId, datas) => {
  return async dispatch => {
    dispatch(editSuggestedOperationPending());

    const {
      investorEmail,
      advisorEmail,
      companyUserEmail,
      operationType,
      portfolioType,
      companyId,
      investorCurrentAccount,
      investorIban,
      investorTradingAccountNumber,
    } = datas;

    try {
      const params = {
        investor_email: investorEmail,
        advisor_email: advisorEmail,
        company_user_email: companyUserEmail,
        operation_type: operationType,
        portfolio_type: portfolioType,
        company_sql_id: companyId,
        investor_current_account: investorCurrentAccount,
        investor_iban: investorIban,
      };
      if (investorTradingAccountNumber) {
        params.trading_account_number = investorTradingAccountNumber;
      }
      await putUpdateSuggestedOperation(investmentDraftLinkId, params);
      dispatch(editSuggestedOperationFulfilled());
      dispatch(getUserInvestmentSuggested());
    } catch (error) {
      dispatch(
        editSuggestedOperationRejected(
          'Une erreur est survenue, veuillez réessayer ultérieurement.',
        ),
      );
    }
  };
};

/**
 |--------------------------------------------------
 | GET USER INVESTMENTS COUNTERS TO INIT DASHBOARD
 |--------------------------------------------------
 */
export const getCounters = () => ({
  type: dashboardConst.INIT_DASHBOARD_COUNTERS,
  payload: getDashboardCounters(),
});

/**
 |--------------------------------------------------
 | GET USER INVESTMENTS TO INIT DASHBOARD
 |--------------------------------------------------
 */

const getInvestmentsCurrentState = state => ({
  userType: state.user.type,
  page: state.dashboard.currentPage,
  perPage: state.dashboard.perPage,
  orderBy: state.dashboard.currentSort.id,
  sort: state.dashboard.currentSort.sort,
  search: state.dashboard.search,
  filters: state.dashboard.filtersData.filtersApplied,
  locale: state.user.locale === 'es' ? 'fr' : state.user.locale,
});

export const downloadTabCSV = (exportOutsideTab = '', fileName = 'catalizr.csv') => {
  return async dispatch => {
    const csv = await dispatch(getUserInvestmentsExportByCurrentTab(exportOutsideTab));
    if (csv && csv.data) {
      fileDownload(csv.data, fileName);
    }
  };
};

export const downloadTabCSVForFunds = (fileName = 'catalizr_fund.csv') => {
  return async dispatch => {
    const csv = await dispatch(getUserInvestmentsExportFundByCurrentTab());
    if (csv && csv.data) {
      fileDownload(csv.data, fileName);
    }
  };
};

const getUserInvestmentsExportByCurrentTab = (exportOutsideTab = '') => {
  return (dispatch, getState) => {
    const state = getState();
    const { dashboard } = state;
    switch (exportOutsideTab) {
      case TABLE_NAME.INVESTOR_WALLET_TABLE: {
        const detailsParams = {
          investorId: dashboard.shareholdingWalletDetails.investor_uuid,
          totalItems: dashboard.shareholdingWalletDetails.data.length,
        };
        return getShareholdingInvestorWalletExport(detailsParams);
      }
      case TABLE_NAME.POSITION_DETAILS_TABLE: {
        const detailsParams = {
          ...getInvestmentsCurrentState(state),
          companyId: dashboard.rowSelectedSharesValuationTable.company_mongo_id,
          totalItems: dashboard.positionsDetails.totalItems,
        };
        return getPositionsDetailsExport(detailsParams);
      }
      case TABLE_NAME.SHARES_VALUATION_DETAILS: {
        const detailsParams = {
          companyId: dashboard.rowSelectedSharesValuationTable.company_mongo_id,
          totalItems: dashboard.rowSelectedSharesValuationTable.values.length,
        };
        return getSharesValuationDetailsByCompanyIdExport(detailsParams);
      }
      default:
        break;
    }
    let csv;
    const params = {
      ...getInvestmentsCurrentState(state),
      creationMethod:
        dashboard.tabIndex === dashboardTab.TRANSMIT
          ? CREATION_METHOD.TRANSMIT
          : CREATION_METHOD.COMPLETE,
    };

    switch (dashboard.tabIndex) {
      case dashboardTab.TRANSMIT:
        params.totalItems = dashboard.counters.transmit;
        csv = getTransmitsListExport(params);
        break;
      case dashboardTab.IN_PROGRESS:
        params.totalItems = dashboard.counters.inProgress;
        csv = getInvestmentsInProgressExport(params);
        break;
      case dashboardTab.CLOSED:
        params.totalItems = dashboard.counters.closed;
        csv = getWalletExport(params);
        break;
      case dashboardTab.CANCELLED:
        params.totalItems = dashboard.counters.cancelled;
        csv = getOperationCancelledByUserTypeExport(params);
        break;
      case dashboardTab.CORPORATE_ACTIONS:
        params.totalItems = dashboard.counters.sharesValuation;
        csv = getSharesValuationExport(params);
        break;
      case dashboardTab.SHAREHOLDING:
        params.totalItems = dashboard.counters.shareholding;
        csv = getShareholdingExport(params);
        break;
      case dashboardTab.FUND_MANAGEMENT:
        csv = exportFundManagement(params);
        break;
      default:
        break;
    }
    return csv;
  };
};

const getUserInvestmentsExportFundByCurrentTab = () => {
  return (dispatch, getState) => {
    const state = getState();
    const { dashboard } = state;
    let csv;

    const params = {
      ...getInvestmentsCurrentState(state),
      creationMethod:
        dashboard.tabIndex === dashboardTab.TRANSMIT
          ? CREATION_METHOD.TRANSMIT
          : CREATION_METHOD.COMPLETE,
    };

    switch (dashboard.tabIndex) {
      case dashboardTab.TRANSMIT:
        params.totalItems = dashboard.counters.transmit;
        csv = getInvestmentsExportForFund(undefined, params);
        break;
      case dashboardTab.IN_PROGRESS:
        params.totalItems = dashboard.counters.inProgress;
        csv = getInvestmentsExportForFund(investmentConst.STATUS_IN_PROGRESS, params);
        break;
      case dashboardTab.CLOSED:
        params.totalItems = dashboard.counters.closed;
        csv = getInvestmentsExportForFund(investmentConst.STATUS_CLOSED, params);
        break;
      case dashboardTab.CANCELLED:
      case dashboardTab.CORPORATE_ACTIONS:
      case dashboardTab.SHAREHOLDING:
      default:
        break;
    }
    return csv;
  };
};

export const getUserInvestmentsByCurrentTab = (outsideTab = null) => {
  return (dispatch, getState) => {
    const state = getState();
    const { dashboard } = state;
    if (outsideTab === TABLE_NAME.POSITION_DETAILS_TABLE) {
      dispatch(setPositionsDetailsInitView(dashboard.rowSelectedSharesValuationTable));
    } else {
      switch (dashboard.tabIndex) {
        case dashboardTab.TRANSMIT:
          return dispatch(getUserTransmitInvestments());
        case dashboardTab.IN_PROGRESS:
          return dispatch(getUserInvestmentsInProgress());
        case dashboardTab.CLOSED:
          return dispatch(getUserWallet());
        case dashboardTab.CANCELLED:
          return dispatch(getUserCancelledInvestments());
        case dashboardTab.CORPORATE_ACTIONS:
          return dispatch(getSharesValuationList());
        case dashboardTab.SHAREHOLDING:
          return dispatch(getShareholdingList());
        case dashboardTab.OPERATION_TEMPLATES:
          return dispatch(getAllOperationTemplates());
        case dashboardTab.OPERATION_SUGGESTED:
          return dispatch(getUserInvestmentSuggested());
        case dashboardTab.FUND_MANAGEMENT:
          return dispatch(getFundList());
        default:
          break;
      }
    }
  };
};

export const getUserTransmitInvestments = () => {
  return (dispatch, getState) => {
    return dispatch({
      type: dashboardConst.GET_TRANSMIT,
      payload: getTransmitsList(getInvestmentsCurrentState(getState())),
    });
  };
};

export const getUserInvestmentsInProgress = () => {
  return (dispatch, getState) => {
    return dispatch({
      type: dashboardConst.GET_INVESTMENTS,
      payload: getInvestmentsInProgress(getInvestmentsCurrentState(getState())),
    });
  };
};

export const getUserInvestmentSuggested = () => {
  return (dispatch, getState) => {
    return dispatch({
      type: dashboardConst.GET_SUGGESSTED_INVESTMENTS,
      payload: getInvestmentsSuggested(getInvestmentsCurrentState(getState())),
    });
  };
};

export const getUserWallet = () => {
  return (dispatch, getState) => {
    return dispatch({
      type: dashboardConst.GET_WALLET,
      payload: getWallet(getInvestmentsCurrentState(getState())),
    });
  };
};

export const getUserCancelledInvestments = () => {
  return (dispatch, getState) => {
    return dispatch({
      type: dashboardConst.GET_CANCELLED,
      payload: getOperationCancelledByUserType(getInvestmentsCurrentState(getState())),
    });
  };
};

/**
 |--------------------------------------------------
 | ALLOW BANK TO EDIT FORM INFORMATIONS FROM DASHBOARD
 |--------------------------------------------------
 */
export const displayDashboardInvestDetails = value => ({
  type: dashboardConst.DISPLAY_INVESTMENT_DETAILS_DASHBOARD,
  value,
});

export const editDashboardInvestDetails = value => ({
  type: dashboardConst.EDIT_INVESTMENT_DETAILS_DASHBOARD,
  value,
});

export const editCompanyEmailDashboardInvestDetails = value => ({
  type: dashboardConst.EDIT_COMPANY_EMAIL_DASHBOARD,
  value,
});

export const editInvestCategoryDashboardInvestDetails = value => ({
  type: dashboardConst.EDIT_INVEST_CATEGORY_DASHBOARD,
  value,
});

export const editCompanyInfoDashboardInvestDetails = value => ({
  type: dashboardConst.EDIT_COMPANY_INFO,
  value,
});

export const editInvestOriginDashboardInvestDetails = value => ({
  type: dashboardConst.EDIT_INVEST_ORIGIN_DASHBOARD,
  value,
});

/**
 |--------------------------------------------------
 | GET FURTHEST AND LATEST STEP
 |--------------------------------------------------
 */

const getFurthestStepPending = () => ({ type: dashboardConst.GET_FURTHEST_STEP_PENDING });

const getFurthestStepFulfilled = payload => ({
  type: dashboardConst.GET_FURTHEST_STEP_FULFILLED,
  payload,
});

const getFurthestStepRejected = () => ({ type: dashboardConst.GET_FURTHEST_STEP_REJECTED });

export const getFurthestStep = investmentLinkId => dispatch => {
  dispatch(getFurthestStepPending());
  getInvestmentFurthestStep(investmentLinkId)
    .then(res => {
      dispatch(getFurthestStepFulfilled(res.data));
    })
    .catch(err => {
      getFurthestStepRejected(err);
    });
};

const getLatestStepPending = () => ({ type: dashboardConst.GET_LATEST_STEP_PENDING });

const getLatestStepFulfilled = payload => ({
  type: dashboardConst.GET_LATEST_STEP_FULFILLED,
  payload,
});

const getLatestStepRejected = () => ({ type: dashboardConst.GET_LATEST_STEP_REJECTED });

export const getLatestStep = investmentLinkId => dispatch => {
  dispatch(getLatestStepPending());
  getInvestmentLatestStep(investmentLinkId)
    .then(res => {
      dispatch(getLatestStepFulfilled(res.data));
    })
    .catch(err => {
      getLatestStepRejected(err);
    });
};

/**
 |--------------------------------------------------
 | ALLOW BANK TO CANCEL OPERATIONS AND POST COMMENTS
 |--------------------------------------------------
 */

export const setModalCancelledOperationsIsOpen = (openModal, investmentLinkId) => dispatch => {
  if (investmentLinkId) {
    dispatch(setInvestmentLinkId(investmentLinkId));
  }
  return dispatch({
    type: dashboardConst.SET_CANCELLED_MODAL_IS_OPEN,
    open: openModal,
  });
};

export const setModalDeleteOperationIsOpen = (openModal, investmentLinkId) => dispatch => {
  if (investmentLinkId) {
    dispatch(setInvestmentLinkId(investmentLinkId));
  }
  return dispatch({
    type: dashboardConst.SET_DELETE_MODAL_IS_OPEN,
    open: openModal,
  });
};

export const setModalRollbackOperationIsOpen = openModal => dispatch => {
  return dispatch({
    type: dashboardConst.SET_ROLLBACK_MODAL_IS_OPEN,
    open: openModal,
  });
};

export const setModalForwardOperationIsOpen = openModal => dispatch => {
  return dispatch({
    type: dashboardConst.SET_FORWARD_MODAL_IS_OPEN,
    open: openModal,
  });
};

export const setModalProofPaymentIsOpen = openModal => dispatch => {
  return dispatch({
    type: dashboardConst.SET_PROOF_PAYMENT_MODAL_IS_OPEN,
    open: openModal,
  });
};

export const resetErrorMsgForOperationCancellation = () => ({
  type: dashboardConst.RESET_CANCELLED_ERROR_MSG,
});

export const resetErrorMsgForOperationClose = () => ({
  type: dashboardConst.CLOSE_OPERATION_ERROR_MESSAGE,
});

const getOperationCommentsPending = () => ({ type: dashboardConst.GET_OPERATION_COMMENTS_PENDING });
const getOperationCommentsFulfilled = payload => ({
  type: dashboardConst.GET_OPERATION_COMMENTS_FULFILLED,
  payload,
});
const getOperationCommentsRejected = err => ({
  type: dashboardConst.GET_OPERATION_COMMENTS_REJECTED,
  err,
});

export const getOperationComments = operationUuid => dispatch => {
  dispatch(getOperationCommentsPending());
  getComments(operationUuid)
    .then(res => {
      dispatch(getOperationCommentsFulfilled(res.data));
    })
    .catch(err => {
      dispatch(getOperationCommentsRejected(err));
    });
};

const postOperationCommentPending = () => ({ type: dashboardConst.POST_OPERATION_COMMENT_PENDING });
const postOperationCommentFulfilled = payload => ({
  type: dashboardConst.POST_OPERATION_COMMENT_FULFILLED,
  payload,
});
const postOperationCommentRejected = err => ({
  type: dashboardConst.POST_OPERATION_COMMENT_REJECTED,
  err,
});

export const postOperationComment = (investmentUuid, comment) => dispatch => {
  dispatch(postOperationCommentPending());
  let body = { comment: comment };
  postComment(investmentUuid, body)
    .then(() => {
      const payload = { ...body, user_email: getStore().getState().user.email };
      dispatch(postOperationCommentFulfilled(payload));
      dispatch(resetCurrentCommentAfterSubmit());
    })
    .catch(err => {
      dispatch(postOperationCommentRejected(err));
      dispatch(clearMessagesEveryXTime(5000));
    });
};

export const setCurrentComment = comment => ({
  type: dashboardConst.SET_CURRENT_COMMENT,
  comment,
});

export const resetCurrentCommentAfterSubmit = () => ({
  type: dashboardConst.RESET_CURRENT_COMMENT_AFTER_SUBMIT,
});

/**
 |--------------------------------------------------
 | METHODS FOR PAGINATION, FILTERS AND SEARCH IN DASHBOARD
 |--------------------------------------------------
 */

export const setCurrentTab = currentTab => ({
  type: dashboardConst.DASHBOARD_SET_CURRENT_TAB,
  currentTab,
});

export const setOpenedFilters = payload => ({
  type: dashboardConst.DASHBOARD_SET_OPENED_FILTERS,
  payload,
});

export const setDashboardFilters = filters => ({
  type: dashboardConst.DASHBOARD_FILTERS_DATA,
  filters,
});

const getFiltersRequestOptions = tabIndex => {
  let filterOptions = {};
  switch (tabIndex) {
    case dashboardTab.TRANSMIT:
      filterOptions.creation_method = CREATION_METHOD.TRANSMIT;
      filterOptions.dashboard_status = null;
      break;
    case dashboardTab.IN_PROGRESS:
      filterOptions.creation_method = CREATION_METHOD.COMPLETE;
      filterOptions.dashboard_status = DASHBOARD_STATUSES.IN_PROGRESS;
      break;
    case dashboardTab.CLOSED:
      filterOptions.creation_method = CREATION_METHOD.COMPLETE;
      filterOptions.dashboard_status = DASHBOARD_STATUSES.CLOSED;
      break;
    case dashboardTab.CANCELLED:
      filterOptions.creation_method = CREATION_METHOD.COMPLETE;
      filterOptions.dashboard_status = DASHBOARD_STATUSES.CANCELLED;
      break;
    case dashboardTab.CORPORATE_ACTIONS:
      filterOptions.dashboard_status = DASHBOARD_STATUSES.CORPORATE_ACTIONS;
      break;
    case dashboardTab.SHAREHOLDING:
      filterOptions.dashboard_status = DASHBOARD_STATUSES.SHAREHOLDING;
      break;
    case dashboardTab.OPERATION_SUGGESTED:
      filterOptions.dashboard_status = DASHBOARD_STATUSES.OPERATION_SUGGESTED;
      break;
    case dashboardTab.FUND_MANAGEMENT:
      filterOptions.dashboard_status = DASHBOARD_STATUSES.FUND_MANAGEMENT;
      break;
    default:
      break;
  }
  return filterOptions;
};

export const initFilterView = tabIndex => {
  const user = getStore().getState().user;
  const usersType = getEntityPathApiFromUserType(user.type);
  const filtersRequestOptions = getFiltersRequestOptions(tabIndex, usersType);
  let filtersData = {};

  return async dispatch => {
    return getOperationFilters(
      usersType,
      user.id,
      user.email,
      filtersRequestOptions.creation_method,
      filtersRequestOptions.dashboard_status,
      dispatch,
    )
      .then(data => {
        filtersData.investorsList =
          data.data.investor_name && data.data.investor_name.length
            ? data.data.investor_name
            : data.data.investor_email
            ? data.data.investor_email
            : [];
        filtersData.companiesList = data.data.company_name;
        filtersData.partTypesList = data.data.part_type;
        filtersData.companySirens = data.data.company_siren;
        filtersData.advisorsList = data.data.advisor_email;
        filtersData.operationsList = data.data.operation_type;
        filtersData.paymentMode = data.data.portfolio_type;
        filtersData.category = data.data.category;
        filtersData.adherentName = data.data.adherent_name;
        filtersData.isin_code = data.data.isin_code;
        filtersData.investor_ibans = data.data.investor_ibans;
        filtersData.fundIsinList = data.data.fundIsinList;
        filtersData.fundNameList = data.data.fundNameList;
        dispatch(setDashboardFilters(filtersData));
      })
      .catch(err => {
        throw err;
      });
  };
};

export const setFiltersToApply = filters => ({
  type: dashboardConst.DASHBOARD_FILTERS_TO_APPLY,
  filters,
});

export const setSearch = search => dispatch => {
  dispatch(setCurrentPage(1));
  dispatch({ type: dashboardConst.SET_SEARCH, search });
};

export const setCounterAndCurrentPageOnFilter = () => {
  return (dispatch, getState) => {
    const dashboardDatas = getState().dashboard;
    // If filters or search applied, we reset currentPage to 1
    if (
      dashboardDatas.currentPage !== 1 &&
      (dashboardDatas.search.length > 0 || dashboardDatas.filtersData.filtersApplied.length > 0)
    ) {
      dispatch(setCurrentPage(1));
    }
  };
};
// reset = 'filters' or 'search' or undefined
export const resetDashboardFilters = reset => {
  return dispatch => {
    if (reset === 'search') {
      dispatch(setSearch(''));
    } else {
      dispatch(setFiltersToApply([]));
    }
    dispatch(setCounterAndCurrentPageOnFilter());
    dispatch(getUserInvestmentsByCurrentTab());
  };
};

export const setCurrentPage = (currentPage, outsideTab = null) => {
  return (dispatch, getState) => {
    if (getState().dashboard.currentPage !== currentPage) {
      dispatch({
        type: dashboardConst.DASHBOARD_SET_CURRENT_PAGE,
        currentPage,
      });
      dispatch(getUserInvestmentsByCurrentTab(outsideTab));
    }
  };
};
export const setPerPage = (perPage, outsideTab = null) => {
  return (dispatch, getState) => {
    if (getState().dashboard.perPage !== perPage) {
      dispatch({
        type: dashboardConst.DASHBOARD_SET_PER_PAGE,
        perPage,
        currentPage: 1,
      });
      dispatch(getUserInvestmentsByCurrentTab(outsideTab));
    }
  };
};

export const setCurrentSort = (currentSort, outsideTab = null) => {
  return (dispatch, getState) => {
    if (
      getState().dashboard.currentSort.id !== currentSort.id ||
      getState().dashboard.currentSort.sort !== currentSort.sort
    ) {
      dispatch({
        type: dashboardConst.DASHBOARD_SET_CURRENT_SORT,
        currentSort,
      });
      dispatch(getUserInvestmentsByCurrentTab(outsideTab));
    }
  };
};

export const setClosedOperationModalIsOpen = open => ({
  type: dashboardConst.SET_CLOSED_MODAL_IS_OPEN,
  open,
});

export const setIsinClosedModalIsOpen = open => ({
  type: dashboardConst.SET_ISIN_CLOSED_MODAL_IS_OPEN,
  open,
});

export const setAttestationModalIsOpen = open => ({
  type: dashboardConst.SET_ATTESTATION_MODAL_IS_OPEN,
  open,
});

/**
 |--------------------------------------------------
 | GET EMAILS TO DISPLAY EMAIL PREVIEW IN USER TIMELINE AND ALLOW BANK TO RESEND THEM
 |--------------------------------------------------
 */

const getEmailsPending = () => ({ type: dashboardConst.GET_EMAILS_PENDING });

const getEmailsFulfilled = payload => ({
  type: dashboardConst.GET_EMAILS_FULFILLED,
  payload,
});

const getEmailsRejected = () => ({ type: dashboardConst.GET_EMAILS_REJECTED });

export const getEmails = investmentLinkId => dispatch => {
  dispatch(getEmailsPending());
  getEmailByLinkId(investmentLinkId)
    .then(res => {
      dispatch(getEmailsFulfilled(res.data));
    })
    .catch(err => {
      getEmailsRejected(err);
    });
};

export const setEmailModalIsOpenWithCurrentEmail = (open, currentEmail) => ({
  type: dashboardConst.EMAIL_MODAL_IS_OPEN,
  open,
  currentEmail,
});

const resendCallToActionEmailPending = () => ({
  type: dashboardConst.RESEND_EMAIL_PENDING,
});

const resendCallToActionEmailFulfilled = () => ({
  type: dashboardConst.RESEND_EMAIL_FULFILLED,
});

const resendCallToActionEmailRejected = () => ({
  type: dashboardConst.RESEND_EMAIL_REJECTED,
});

export const resendCallToActionEmail = mailjetUuid => {
  return dispatch => {
    dispatch(resendCallToActionEmailPending());
    resendEmail(mailjetUuid)
      .then(() => {
        dispatch(resendCallToActionEmailFulfilled());
      })
      .catch(() => {
        dispatch(resendCallToActionEmailRejected());
      })
      .finally(() => {
        dispatch(clearMessagesEveryXTime(4000));
      });
  };
};

/**
 |--------------------------------------------------
 | SHARES VALUATION ACTIONS FOR BACKOFFICE AND COMPANY
 |--------------------------------------------------
 */

export const getSharesValuationList = () => {
  return (dispatch, getState) => {
    if (isUserInvestor()) {
      return dispatch({
        type: dashboardConst.GET_SHARES_VALUATION_POSITIONS_LIST,
        payload: getInvestorSharesValuationPositionList(),
      });
    }
    return dispatch({
      type: dashboardConst.GET_SHARES_VALUATION_LIST,
      payload: getBackofficeSharesValuationList(getInvestmentsCurrentState(getState())),
    });
  };
};

const setSharesValuationDetailsInitViewPending = row => ({
  type: dashboardConst.SET_SHARES_VALUATION_DETAILS_INIT_VIEW_PENDING,
  row,
});

const setSharesValuationDetailsInitViewRejected = row => ({
  type: dashboardConst.SET_SHARES_VALUATION_DETAILS_INIT_VIEW_REJECTED,
  row,
});

const setSharesValuationDetailsInitViewFulfilled = res => ({
  type: dashboardConst.SET_SHARES_VALUATION_DETAILS_INIT_VIEW_FULFILLED,
  res,
});

export const setSharesValuationDetailsInitView = (row, history) => dispatch => {
  if (row) {
    dispatch(setSharesValuationDetailsInitViewPending(row));
    getSharesValuationDetailsByCompanyId(row.company_mongo_id)
      .then(res => {
        const response = { ...row, values: res.data };
        dispatch(setSharesValuationDetailsInitViewFulfilled(response));
      })
      .catch(() => {
        dispatch(setSharesValuationDetailsInitViewRejected(row));
      });
  } else {
    const user = getStore().getState().user;
    history.push(`/${getEntityPathFromUserType(user.type)}/dashboard`);
  }
};

export const getUpdatedSharesValuationByCompany = updatedValues => ({
  type: dashboardConst.GET_UPDATED_SHARES_VALUATION_BY_COMPANY,
  updatedValues,
});

const setSubmitSharesValuationUpdatedPending = updatedValues => ({
  type: dashboardConst.SET_SUBMIT_COMPANY_SHARES_VALUATION_PENDING,
  updatedValues,
});

const setSubmitSharesValuationUpdatedFulfilled = updatedValues => ({
  type: dashboardConst.SET_SUBMIT_COMPANY_SHARES_VALUATION_FULFILLED,
  updatedValues,
});

const setSubmitSharesValuationUpdatedRejected = updatedValues => ({
  type: dashboardConst.SET_SUBMIT_COMPANY_SHARES_VALUATION_REJECTED,
  updatedValues,
});

export const submitCompanySharesValuation = (rowSelected, updatedValues, history) => dispatch => {
  const isProofDocProvided = rowSelected.proof_doc ? rowSelected.proof_doc.id : null;
  const body = [{ company_mongo_id: rowSelected.company_mongo_id }];
  const bodyValues = rowSelected.values.reduce((accu, data, index) => {
    if (updatedValues[index].value !== '' || updatedValues[index].date !== null) {
      accu.push({
        price: updatedValues[index].value,
        nature: data.part_type,
        category: data.category,
        fund_name: data.fund_name,
        fund_isin: data.fund_isin,
        effective_date: dayjs(updatedValues[index].date).format('YYYY-MM-DD'),
        proof_doc_id: isProofDocProvided,
      });
    }
    return accu;
  }, []);
  body[0].values = bodyValues;
  dispatch(setSubmitSharesValuationUpdatedPending());
  postCompanyUpdatedSharesValuation(body)
    .then(() => {
      dispatch(setSubmitSharesValuationUpdatedFulfilled());
      dispatch(setSharesValuationDetailsInitView(rowSelected, history));
    })
    .catch(err => {
      dispatch(setSubmitSharesValuationUpdatedRejected(err));
    })
    .finally(() => {
      dispatch(clearMessagesEveryXTime(4000));
    });
};

export const submitInvestorSharesValuation = data => async dispatch => {
  dispatch(setSubmitSharesValuationUpdatedPending());
  return await postCompanyUpdatedSharesValuation(data)
    .then(() => {
      dispatch(setSubmitSharesValuationUpdatedFulfilled());
    })
    .catch(err => {
      dispatch(setSubmitSharesValuationUpdatedRejected(err));
    })
    .finally(() => {
      dispatch(clearMessagesEveryXTime(4000));
    });
};

const deleteDocumentShareValuationPending = () => ({
  type: dashboardConst.DELETE_DOCUMENT_SHARES_VALUATION_PENDING,
});

const deleteDocumentShareValuationFulfilled = () => ({
  type: dashboardConst.DELETE_DOCUMENT_SHARES_VALUATION_FULFILLED,
});

const deleteDocumentShareValuationRejected = () => ({
  type: dashboardConst.DELETE_DOCUMENT_SHARES_VALUATION_REJECTED,
});

export const deleteDocumentSharesValuation = (companyId, documentId) => dispatch => {
  dispatch(deleteDocumentShareValuationPending());
  deleteDocumentSharesValuations(companyId, documentId)
    .then(() => {
      dispatch(deleteDocumentShareValuationFulfilled());
    })
    .catch(() => {
      dispatch(deleteDocumentShareValuationRejected());
    });
};

/**
 |--------------------------------------------------
 | SHAREHOLDING ACTIONS FOR COMPANY
 |--------------------------------------------------
 */
const TIMEOUT_BEFORE_CLEANING_MSG_SHAREHOLDING = 3000;

export const getShareholdingList = () => {
  return (dispatch, getState) => {
    return dispatch({
      type: dashboardConst.GET_SHAREHOLDING_LIST,
      payload: getCompanyShareholdingList(getInvestmentsCurrentState(getState())),
    });
  };
};

export const getFundList = () => {
  return (dispatch, getState) => {
    return dispatch({
      type: dashboardConst.GET_FUND_LIST,
      payload: retrieveFundList(getInvestmentsCurrentState(getState())),
    });
  };
};

const setShareholdingWalletInitViewPending = () => ({
  type: dashboardConst.SET_SHAREHOLDING_DETAILS_INIT_VIEW_PENDING,
});

const setShareholdingWalletInitViewRejected = () => ({
  type: dashboardConst.SET_SHAREHOLDING_DETAILS_INIT_VIEW_REJECTED,
});

const setShareholdingWalletInitViewFulfilled = res => ({
  type: dashboardConst.SET_SHAREHOLDING_DETAILS_INIT_VIEW_FULFILLED,
  res,
});

export const setShareholdingWalletInitView = (investorId, history) => dispatch => {
  if (investorId) {
    dispatch(setShareholdingWalletInitViewPending());
    getShareholdingDetailsByInvestorId(investorId)
      .then(res => {
        dispatch(setShareholdingWalletInitViewFulfilled(res.data));
      })
      .catch(() => {
        dispatch(setShareholdingWalletInitViewRejected());
      });
  } else {
    history.push('/company/dashboard');
  }
};

const sendShareholdingManualInsertionPending = () => ({
  type: dashboardConst.SEND_SHAREHOLDING_MANUAL_INSERTION_PENDING,
});

const sendShareholdingManualInsertionRejected = () => ({
  type: dashboardConst.SEND_SHAREHOLDING_MANUAL_INSERTION_REJECTED,
});

const sendShareholdingManualInsertionFulfilled = () => ({
  type: dashboardConst.SEND_SHAREHOLDING_MANUAL_INSERTION_FULFILLED,
});

export const sendShareholdingManualInsertion =
  (values, investorId, history, closeModal) => dispatch => {
    if (values) {
      dispatch(sendShareholdingManualInsertionPending());
      // Quantity has to be sent as negative if operation type is disposal
      const body = {
        ...values,
        part_amount: formatFloatValue(values.part_amount),
        quantity:
          values.operation_type === OPERATION_TYPE_DISPOSAL ? -values.quantity : values.quantity,
      };

      if (investorId) {
        body.investor_uuid = investorId;
      } else {
        delete body.investor_uuid;
      }
      postCompanyShareholdingManualInsertion(body)
        .then(() => {
          dispatch(sendShareholdingManualInsertionFulfilled());
        })
        .catch(() => {
          dispatch(sendShareholdingManualInsertionRejected());
        })
        .finally(() => {
          dispatch(clearMessagesEveryXTime(TIMEOUT_BEFORE_CLEANING_MSG_SHAREHOLDING));
          if (values.investor_email) {
            setTimeout(() => {
              closeModal();
              dispatch(getUserInvestmentsByCurrentTab());
            }, TIMEOUT_BEFORE_CLEANING_MSG_SHAREHOLDING);
          } else {
            setTimeout(() => {
              closeModal();
              dispatch(setShareholdingWalletInitView(investorId, history));
            }, TIMEOUT_BEFORE_CLEANING_MSG_SHAREHOLDING);
          }
        });
    } else {
      history.push('/company/dashboard');
    }
  };

const deleteShareholdingManuallyInsertedPending = () => ({
  type: dashboardConst.DELETE_SHAREHOLDING_MANUALLY_INSERTED_PENDING,
});

const deleteShareholdingManuallyInsertedRejected = () => ({
  type: dashboardConst.DELETE_SHAREHOLDING_MANUALLY_INSERTED_REJECTED,
});

const deleteShareholdingManuallyInsertedFulfilled = () => ({
  type: dashboardConst.DELETE_SHAREHOLDING_MANUALLY_INSERTED_FULFILLED,
});

export const deleteShareholdingManuallyInserted =
  (shareholdingUuid, investorId, posId, history, closeModal, data, redirect = true) =>
  dispatch => {
    dispatch(deleteShareholdingManuallyInsertedPending());
    deleteCompanyShareholdingManualInsertion(shareholdingUuid, posId)
      .then(() => {
        dispatch(deleteShareholdingManuallyInsertedFulfilled());
      })
      .catch(() => {
        dispatch(deleteShareholdingManuallyInsertedRejected());
      })
      .finally(() => {
        dispatch(clearMessagesEveryXTime(TIMEOUT_BEFORE_CLEANING_MSG_SHAREHOLDING));
        setTimeout(() => {
          closeModal();
          if (!redirect) {
            dispatch(getInvestorUpdatedPositionsByCompany({}));
            dispatch(setPositionsDetailsInitView(data, history));
            return;
          }
          // If user delete the only shareholding from the current investor wallet
          // We redirect him on his dashboard
          if (data.length > 1) {
            dispatch(setShareholdingWalletInitView(investorId, history));
          } else {
            history.push('/company/dashboard');
          }
        }, TIMEOUT_BEFORE_CLEANING_MSG_SHAREHOLDING);
      });
  };

/**
 |--------------------------------------------------
 | CONFIRMATION DES POSITIONS
 |--------------------------------------------------
 */
const setPositionsDetailsInitViewPending = row => ({
  type: dashboardConst.SET_POSITIONS_DETAILS_INIT_VIEW_PENDING,
  row,
});

const setPositionsDetailsInitViewRejected = row => ({
  type: dashboardConst.SET_POSITIONS_DETAILS_INIT_VIEW_REJECTED,
  row,
});

const setPositionsDetailsInitViewFulfilled = res => ({
  type: dashboardConst.SET_POSITIONS_DETAILS_INIT_VIEW_FULFILLED,
  res,
});

export const setSelectedSuggestedInvestment = id => ({
  type: dashboardConst.SET_SELECTED_SUGGESTED_OPERATION,
  selectedSuggestedInvestment: id,
});

export const setPositionsDetailsInitView = (row, history) => {
  return (dispatch, getState) => {
    if (row) {
      const params = getInvestmentsCurrentState(getState());
      params.companyId = row.company_mongo_id;
      dispatch(setPositionsDetailsInitViewPending(row));
      getPositionsDetailsByCompanyId(params)
        .then(res => {
          dispatch(setPositionsDetailsInitViewFulfilled(res.data));
        })
        .catch(() => {
          dispatch(setPositionsDetailsInitViewRejected(row));
        });
    } else {
      if (history) {
        history.push('/company/dashboard');
      } else {
        dispatch(setPositionsDetailsInitViewRejected(row));
      }
    }
  };
};

export const getInvestorUpdatedPositionsByCompany = updatedQuantities => ({
  type: dashboardConst.GET_INVESTOR_UPDATED_POSITIONS_BY_COMPANY,
  updatedQuantities,
});

const sendPositionsUpdatedPending = () => ({
  type: dashboardConst.SEND_POSITIONS_UPDATED_PENDING,
});

const sendPositionsUpdatedRejected = () => ({
  type: dashboardConst.SEND_POSITIONS_UPDATED_REJECTED,
});

const sendPositionsUpdatedFulfilled = updatedQuantities => ({
  type: dashboardConst.SEND_POSITIONS_UPDATED_FULFILLED,
  updatedQuantities,
});

export const sendPositionsUpdated = (values, row, companyId, history) => dispatch => {
  if (values) {
    dispatch(sendPositionsUpdatedPending());
    const body = {
      data: [],
      company_id: companyId,
    };
    const delta = {};
    Object.values(values).forEach(value => {
      let formattedDatas = {};
      if (value.edited) {
        if (Number(value.quantityConfirmed) < 0) {
          delta[value.uuid] = value;
          return -1;
        }
        formattedDatas = { ...value };
        delete formattedDatas.edited;
        delete formattedDatas.quantityConfirmed;
        formattedDatas.company_id = companyId;
        formattedDatas.operation_type =
          Number(value.quantityConfirmed) === 0
            ? Enum.OST.Shareholding.MovementType.LIQUIDATE
            : Enum.OST.Shareholding.MovementType.ADJUST;
        formattedDatas.quantity = value.quantityConfirmed - value.quantity;
        formattedDatas.quantity_confirmed = value.quantityConfirmed;
        return body.data.push(formattedDatas);
      }
      return body.data.push(formattedDatas);
    });
    postCompanyUpdatedPositions(body)
      .then(() => {
        dispatch(sendPositionsUpdatedFulfilled(delta));
      })
      .catch(() => {
        dispatch(sendPositionsUpdatedRejected());
      })
      .finally(() => {
        dispatch(clearMessagesEveryXTime(TIMEOUT_BEFORE_CLEANING_MSG_SHAREHOLDING));
        setTimeout(async () => {
          await dispatch(getInvestorUpdatedPositionsByCompany({}));
          dispatch(setPositionsDetailsInitView(row, history));
        }, TIMEOUT_BEFORE_CLEANING_MSG_SHAREHOLDING);
      });
  } else {
    history.push('/company/dashboard');
  }
};

const addNewInvestorPositionPending = () => ({
  type: dashboardConst.ADD_NEW_INVESTOR_POSITION_PENDING,
});

const addNewInvestorPositionRejected = () => ({
  type: dashboardConst.ADD_NEW_INVESTOR_POSITION_REJECTED,
});

const addNewInvestorPositionFulfilled = () => ({
  type: dashboardConst.ADD_NEW_INVESTOR_POSITION_FULFILLED,
});

export const addNewInvestorPosition = (values, row, companyId, closeModal, history) => {
  return async dispatch => {
    if (values) {
      const valuesToInsert = { ...values };
      const body = {
        data: [],
        company_id: companyId,
        new_insertion: true,
      };
      valuesToInsert.company_id = companyId;
      valuesToInsert.operation_type = Enum.OST.Shareholding.MovementType.ADJUST;
      body.data.push(valuesToInsert);
      dispatch(addNewInvestorPositionPending());
      postCompanyUpdatedPositions(body)
        .then(() => {
          dispatch(addNewInvestorPositionFulfilled());
        })
        .catch(error => {
          dispatch(addNewInvestorPositionRejected());
          const response = error.response;
          const errorMessageToDisplay = response.status >= 400 ? response.data.message : null;
          if (typeof errorMessageToDisplay === 'string') {
            errorMessageToDisplay === 'User with email exist but with an other user type'
              ? dispatch(
                  setErrorMessage(
                    "L'adresse email de l'investisseur saisie existe dans notre référentiel pour un autre type d'utilisateur, veuillez corriger l’email.",
                  ),
                )
              : errorMessageToDisplay === 'INTERNAL_SERVER_ERROR'
              ? dispatch(
                  setErrorMessage('Un problème est survenu merci de contacter support@catalizr.eu'),
                )
              : dispatch(setErrorMessage(errorMessageToDisplay));
            closeModal();
          }
        })
        .finally(() => {
          dispatch(clearMessagesEveryXTime(TIMEOUT_BEFORE_CLEANING_MSG_SHAREHOLDING));
          setTimeout(async () => {
            await dispatch(getInvestorUpdatedPositionsByCompany({}));
            await dispatch(setPositionsDetailsInitView(row, history));
            closeModal();
          }, TIMEOUT_BEFORE_CLEANING_MSG_SHAREHOLDING);
        });
    } else {
      history.push('/company/dashboard');
    }
  };
};

// ---------------------------------------------
// OPERATION TEMPLATES
// ---------------------------------------------

const getAllOperationTemplates = (domain = null) => {
  return (dispatch, getState) => {
    const currentSort = getState().dashboard ? getState().dashboard.currentSort : null;
    const entityPath = getEntityPathFromUserType(getState().user.type);
    return dispatch({
      type: dashboardConst.GET_OPERATION_TEMPLATES,
      payload: getAllTemplates(domain, currentSort, entityPath, true),
    });
  };
};

export const updateOperationFlagStatus = (operationId, flagStatus) => {
  return async dispatch => {
    const payload = {
      operationId,
      flagStatus,
    };
    if (false === isFeatureEnabled('allow_operation_flag_management')) {
      return;
    }

    const res = await putUpdateOperationFlagStatus(payload);

    if (res && res.data) {
      return dispatch({
        type: dashboardConst.SET_OPERATION_FLAG_STATUS_FULFILLED,
        payload: {
          operationId: res.data.id,
          flagStatus: res.data.is_flagged,
        },
      });
    }
  };
};
